import { css } from '@emotion/react';

export const resetStyles = {
  ul: css({
    listStyle: 'none',
    padding: '0',
    margin: '0',
  }),
  button: css({
    cursor: 'pointer',
    border: 'none',
    background: 'transparent',
  }),
  p: css({
    margin: '0',
  }),
  heading: css({ marginBottom: 0 }),
};
