import React, { useEffect } from 'react';
import { useBrooklynUserJwtContext } from '@yi/core/src/brooklyn-jwt/BrooklynUserJwtProvider';

import { nr } from '~/common';
import { useCurrentUser } from '~/src/common/user';

export const BrooklynJwtValidation: React.FC = () => {
  const { currentUser } = useCurrentUser();
  const { parsedJwt, error } = useBrooklynUserJwtContext();

  const jwtGaiaId = parsedJwt?.uuid;
  const userGaiaId = currentUser?.gaia_id;
  const userId = currentUser?.id;

  useEffect(() => {
    if (!userId || !userGaiaId || !jwtGaiaId) return;

    const matches = userGaiaId === jwtGaiaId;
    nr.addAction(nr.VALIDATE_JWT_MATCHES_USER, {
      userId,
      userGaiaId,
      jwtGaiaId,
      matches,
      error,
    });
  }, [userId, userGaiaId, jwtGaiaId, error]);

  return null;
};
