import { hasAlreadySeen } from './hasAlreadySeen';
import { isNextShowReached } from './isNextShowReached';
import { isPromoEnded } from './isPromoEnded';

export function shouldShowPromo() {
  if (isPromoEnded()) {
    return false;
  }

  if (hasAlreadySeen()) {
    return false;
  }

  if (!isNextShowReached()) {
    return false;
  }

  return true;
}
