import React from 'react';

import { isSSR } from '~/common';
import { useCurrentUser } from '~/src/common/user';

import { isUrlPathnameInBlackList } from './isUrlPathnameInBlackList';
import { NewContentAlertModal } from './NewContentAlertModal';
import { usePromoState } from './usePromoState';

export const NewContentAlert: React.FC = () => {
  const { open, postponePromo, rememberChoice, shouldShowPromo } = usePromoState();
  const { isLoggedIn, loading } = useCurrentUser();

  if (isSSR()) {
    return null;
  }
  if (loading || !isLoggedIn) {
    return null;
  }

  if (!shouldShowPromo()) {
    return null;
  }
  if (isUrlPathnameInBlackList(location.pathname)) {
    return null;
  }
  return (
    <NewContentAlertModal
      open={open}
      rememberChoice={rememberChoice}
      postponePromo={postponePromo}
    />
  );
};
