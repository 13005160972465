import React, { useContext, useEffect, PropsWithChildren } from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import Box from '@mui/material/Box';
import { WindowLocation } from '@reach/router';
import querystring from 'querystring';
import { createPortal } from 'react-dom';
import { gql } from '@yi/core';
import { i18n_exit_playlist } from '@yi/i18n/phrases/i18n_exit_playlist';

import { notifyBugsnag, SSR, utilities } from '~/common';
import { dustyOrange, jwPlayerModalZIndex, playlistHeight, expandedPlaylistHeight } from '~/styles';

import { PlaylistWrapper } from './elements';
import { Heading } from './Heading';
import { PlaylistContext } from './PlaylistProvider';
import { useLocale } from '../LocaleProvider';

const { truncate, navigate } = utilities;

interface Props {
  playlistId: string;
  pathName: string;
}

export const PlaylistPlayer = ({ location }: { location: WindowLocation }) => {
  if (SSR) return;
  const urlParams = querystring.parse(location.search.substring(1));
  const playlist_id = urlParams?.playlistId;
  const playlistId = typeof playlist_id === 'string' ? playlist_id : '';
  const pathName = window.location.pathname;
  const urlContentType = ['article/', 'class/', 'meditation/'].some(item => {
    return pathName.includes(item);
  });
  const showPlayList = urlContentType && playlistId;

  if (!showPlayList) return null;
  return <PlaylistDrawer playlistId={playlistId} pathName={pathName} />;
};

const PlaylistDrawer: React.FC<PropsWithChildren<Props>> = ({ playlistId, pathName }) => {
  const { updatePlayListData, currentIndex, showDetailView, setShowDetailView, playlistEnabled } =
    useContext(PlaylistContext);
  const { locale } = useLocale();
  const { data, loading } = useQuery<
    gql.typesClient.Query_PlayList_PlaylistPlayer,
    gql.typesClient.Query_PlayList_PlaylistPlayerVariables
  >(gql.Query_PlayList_PlaylistPlayer, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: playlistId,
    },
    ssr: false,
    onError: error => {
      notifyBugsnag(error);
    },
  });
  const playlistData = data?.Playlist?.items;
  useEffect(() => {
    if (playlistData) {
      updatePlayListData(playlistData, pathName, playlistId);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlistData, pathName, playlistId]);

  useEffect(() => {
    return () => {
      updatePlayListData([], '', '');
    };
  }, [updatePlayListData]);

  if (!playlistData || !playlistData.length || !playlistEnabled || loading) {
    return null;
  }
  const currentItem = playlistData[currentIndex] ? playlistData[currentIndex] : null;
  const previousItem = currentIndex !== 0 ? playlistData[currentIndex - 1] : null;
  const nextItem = currentIndex < playlistData.length - 1 ? playlistData[currentIndex + 1] : null;
  if (!currentItem) return null;

  return createPortal(
    <PlaylistWrapper
      style={{
        height: showDetailView ? expandedPlaylistHeight : playlistHeight,
        zIndex: jwPlayerModalZIndex + 100,
      }}
    >
      <Box display="flex" alignItems="center" mx="8px">
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Box width="75px">
            <img
              src={currentItem.content.square_image.processed_url}
              style={{
                width: showDetailView ? '75px' : '35px',
                transition: 'all 0.5s ease',
              }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          justifyContent="center"
          alignItems="center"
          marginLeft={{ xs: '25px', md: '0px' }}
        >
          <Box
            width={{ xs: '220px', sm: '340px', md: '360px' }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SkipPreviousIcon
              onClick={() =>
                previousItem &&
                navigate(
                  `${previousItem.content.localized_path}?playlistId=${playlistId}`,
                  {},
                  { doNotLocalize: true },
                )
              }
              style={{
                color: !previousItem ? 'grey' : 'white',
                visibility: previousItem ? 'visible' : 'hidden',
              }}
            />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Box component="span" color="white" px="6px" fontSize="14px" textAlign="center">
                {truncate(currentItem.content.title, 23, '...')}
              </Box>
              {showDetailView && (
                <>
                  <Heading path={currentItem.content.localized_path} />
                  <Box sx={{ display: { sm: 'none', xs: 'block' } }}>
                    <Box display="flex" height="20px" justifyContent="center" alignItems="center">
                      <Box
                        component="span"
                        color={dustyOrange}
                        fontSize="12px"
                        style={{
                          cursor: 'pointer',
                          textTransform: 'uppercase',
                          width: '166px',
                          textAlign: 'center',
                        }}
                        onClick={() => navigate(`/playlist/?id=${playlistId}`)}
                      >
                        {i18n_exit_playlist(locale)}
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Box>

            <SkipNextIcon
              data-testid="playlist-next-button"
              onClick={() =>
                nextItem &&
                navigate(
                  `${nextItem.content.localized_path}?playlistId=${playlistId}`,
                  {},
                  { doNotLocalize: true },
                )
              }
              style={{ color: 'white', visibility: nextItem ? 'visible' : 'hidden' }}
            />
          </Box>
        </Box>
        <Box
          width={{ xs: '25px', md: showDetailView ? '75px' : '35px' }}
          display="flex"
          flexDirection="column"
          height="50px"
          justifyContent={showDetailView ? 'flex-start' : 'center'}
          alignItems="flex-end"
          px="2px"
        >
          {!showDetailView ? (
            <KeyboardArrowUpIcon
              onClick={() => setShowDetailView(true)}
              style={{ color: 'white' }}
            />
          ) : (
            <>
              <KeyboardArrowDownIcon
                onClick={() => setShowDetailView(false)}
                style={{ color: 'white' }}
              />

              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Box
                  display="flex"
                  height="20px"
                  justifyContent="center"
                  alignItems="center"
                  mt="8px"
                  mr="5px"
                >
                  <Box
                    component="span"
                    color={dustyOrange}
                    fontSize="14px"
                    style={{ cursor: 'pointer', textTransform: 'uppercase', width: '116px' }}
                    onClick={() => navigate(`/playlist/?id=${playlistId}`)}
                  >
                    {i18n_exit_playlist(locale)}
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </PlaylistWrapper>,
    document.body,
  );
};
