import axios from 'axios';
import { WEB_CLIENT_ATTR_HEADER } from '@yi/core/src/gaia';

import { getConfig } from '../getConfig';
import { loggers } from '../loggers';
import { nr } from '../newRelic';
import { tryJsonStringify } from '../tryJsonStringify';

const logger = loggers.brooklyn;

export const brooklyn = axios.create({
  baseURL: getConfig('BROOKLYN_URL'),
  timeout: 10000,
  headers: {
    common: { ...WEB_CLIENT_ATTR_HEADER },
  },
});

brooklyn.interceptors.request.use(request => {
  try {
    const { url, data, params, method } = request;
    nr.addAction(nr.BROOKLYN_REQUEST, {
      method,
      url,
      data: tryJsonStringify(data),
      params: tryJsonStringify(params),
    });
    logger.log(`REQUEST: ${method?.toUpperCase()} ${url}`, data || '', params || '');
  } catch (e) {
    console.error(e);
    nr.noticeError(e instanceof Error ? e : `${e}`);
  }
  return request;
});

brooklyn.interceptors.response.use(response => {
  try {
    const {
      config: { method, url },
      status,
      data,
    } = response;
    nr.addAction(nr.BROOKLYN_RESPONSE, { method, url, data: tryJsonStringify(data), status });
    logger.log(
      `RESPONSE: ${method?.toUpperCase()} ${url}`,
      status,
      JSON.stringify(data).slice(0, 30) + '...',
    );
  } catch (e) {
    console.error(e);
    nr.noticeError(e instanceof Error ? e : `${e}`);
  }
  return response;
});
