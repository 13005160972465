// https://stackoverflow.com/a/38552302
export const parseJwt = (jwt: string) => {
  const base64Url = jwt.split('.')[1];
  if (!base64Url) return '';
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );

  return JSON.parse(jsonPayload);
};
