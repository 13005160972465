import React, {
  useState,
  SetStateAction,
  Dispatch,
  useCallback,
  PropsWithChildren,
  useContext,
} from 'react';
import styled from '@emotion/styled';
import MuiBox from '@mui/material/Box';
import { gql } from '@yi/core';

import { isSamePathname, notifyBugsnag } from '~/common';
import { playlistHeight, expandedPlaylistHeight } from '~/styles';

interface PlaylistContextType {
  autoplay: boolean;
  setAutoplay: Dispatch<SetStateAction<boolean>>;
  playlistData: gql.typesClient.Query_PlayList_PlaylistPlayer_Playlist_items[];
  currentIndex: number;
  setCurrentIndex: Dispatch<SetStateAction<number>>;
  updatePlayListData(
    playlistData: gql.typesClient.Query_PlayList_PlaylistPlayer_Playlist_items[],
    pathName: string,
    playlistId: string,
  ): void;
  showDetailView: boolean;
  setShowDetailView: Dispatch<SetStateAction<boolean>>;
  playlistId: string;
  setPlaylistId: Dispatch<SetStateAction<string>>;
  playlistEnabled: boolean;
}

const Box = styled(MuiBox)``;

const warnMissingProvider = () => notifyBugsnag('Missing PlaylistContext.Provider');
export const PlaylistContext = React.createContext<PlaylistContextType>({
  autoplay: false,
  setAutoplay: warnMissingProvider,
  playlistData: [],
  currentIndex: -1,
  setCurrentIndex: warnMissingProvider,
  updatePlayListData: warnMissingProvider,
  showDetailView: false,
  setShowDetailView: warnMissingProvider,
  playlistId: '',
  setPlaylistId: warnMissingProvider,
  playlistEnabled: false,
});

export const PlaylistProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [autoplay, setAutoplay] = useState(true);
  const [playlistId, setPlaylistId] = useState('');
  const [showDetailView, setShowDetailView] = useState(false);
  const [playlistEnabled, setPlaylistEnabled] = useState(false);
  const [playlistData, setPlaylistData] = useState<
    gql.typesClient.Query_PlayList_PlaylistPlayer_Playlist_items[]
  >([]);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const updatePlayListData = useCallback(
    (
      playlistData_: gql.typesClient.Query_PlayList_PlaylistPlayer_Playlist_items[],
      pathName: string,
      playlistId_: string,
    ) => {
      if (playlistData_.length === 0) {
        setCurrentIndex(-1);
        setShowDetailView(false);
        setPlaylistEnabled(false);
      } else {
        const contentIndex = playlistData_.findIndex(
          (item: gql.typesClient.Query_PlayList_PlaylistPlayer_Playlist_items) => {
            return item ? isSamePathname(item.content.localized_path, pathName) : false;
          },
        );
        setCurrentIndex(contentIndex);
        setPlaylistEnabled(contentIndex === -1 ? false : true);
      }
      setPlaylistData(playlistData_);
      setPlaylistId(playlistId_);
    },
    [],
  );

  return (
    <PlaylistContext.Provider
      value={{
        autoplay,
        setAutoplay,
        playlistData,
        currentIndex,
        setCurrentIndex,
        updatePlayListData,
        showDetailView,
        setShowDetailView,
        playlistId,
        setPlaylistId,
        playlistEnabled,
      }}
    >
      <Box
        height={
          playlistEnabled
            ? showDetailView
              ? `calc(100vh - ${expandedPlaylistHeight})`
              : `calc(100vh - ${playlistHeight})`
            : '100vh'
        }
        style={{
          transition: !showDetailView ? 'all 0.3s ease' : 'all 0.7s ease',
        }}
      >
        {children}
      </Box>
    </PlaylistContext.Provider>
  );
};

export function usePlaylist() {
  const context = useContext(PlaylistContext);
  if (typeof context === 'undefined') {
    notifyBugsnag('usePlaylist must be used within a PlaylistContext');
  }
  return context;
}
