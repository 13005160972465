export const memoizeFunc = <F extends (...args: any[]) => any>(
  func: F,
  cacheDurationMs: number,
  skipMemoIf?: (a: ReturnType<F>) => boolean,
) => {
  let lastValue: ReturnType<F>;
  let validThru = 0;
  return (...args: Parameters<F>) => {
    if (Date.now() <= validThru) return lastValue; // return cached value
    const result = func(...args);
    if (skipMemoIf?.(result)) return result; // skip memoization
    lastValue = result;
    validThru = Date.now() + cacheDurationMs;
    return lastValue; // return newly computed value
  };
};
