import React, { PropsWithChildren, useCallback } from 'react';
import { useApolloClient } from '@apollo/client/react/hooks';
import { gql, TSFixMe } from '@yi/core';
import * as core from '@yi/core/src/brooklyn-jwt/BrooklynUserJwtProvider';
import { EntitlementsError } from '@yi/core/src/custom-errors';

import { brooklyn, getAsyncStorage, nr } from '~/common';
import { useCurrentUser } from '~/src/common/user';

const asyncStorage = getAsyncStorage();

export const BrooklynUserJwtProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { currentUser, loading: currentUserLoading } = useCurrentUser();
  const apolloClient = useApolloClient();

  const fetchUserJwt = useCallback(async () => {
    const { error, errors, data } = await apolloClient.query<
      gql.typesClient.Query_CurrentUserBrooklynJwt,
      {}
    >({ query: gql.Query_CurrentUserBrooklynJwt, fetchPolicy: 'network-only' });
    if (error) throw new Error(`${error}`);
    if (errors) throw new Error(`${errors.join(', ')}`);
    if (!data.CurrentUserBrooklynJwt?.jwt) throw new Error('CurrentUserBrooklynJwt empty');
    return data.CurrentUserBrooklynJwt?.jwt;
  }, [apolloClient]);

  const onError = useCallback(
    async (e: Error) => {
      nr.noticeError(new EntitlementsError(e), { userId: currentUser?.id || '' });
    },
    [currentUser?.id],
  );

  return (
    <core.BrooklynUserJwtProvider
      currentUser={currentUser}
      currentUserLoading={currentUserLoading}
      fetchUserJwt={fetchUserJwt}
      renewUserJwt={brooklyn.renewUserJwt}
      asyncStorage={asyncStorage as TSFixMe}
      onError={onError}
    >
      {children}
    </core.BrooklynUserJwtProvider>
  );
};
