import { BrooklynResponses, BrooklynRequests } from '@yi/core/src/types/brooklyn';

import { brooklyn } from './brooklyn';
import { makeAuthHeader } from './makeAuthHeader';
import { nr } from '../newRelic';

const path = '/v1/renew';
type Request = BrooklynRequests['/v1/renew'];
type Response = BrooklynResponses['/v1/renew'];

export const renewUserJwt = async (jwt: string) => {
  try {
    const payload: Request = null;
    const result = await brooklyn.post<Response>(path, payload, {
      headers: makeAuthHeader(jwt),
    });
    if (!result?.data?.success) {
      throw new Error(`Unexpected response ${JSON.stringify(result.data)}`);
    }

    nr.addAction(nr.FETCH_BROOKLYN, { path, success: true });

    return result.data;
  } catch (error) {
    nr.addAction(nr.FETCH_BROOKLYN, { path, success: false, error });
    throw error;
  }
};
