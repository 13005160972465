import React, { useContext, PropsWithChildren, useMemo } from 'react';
import { Locale } from '@yi/core';

interface Props {
  locale: Locale;
}

export const LocaleContext = React.createContext<Props | null>(null);
export const LocaleProvider: React.FC<PropsWithChildren<Props>> = ({ locale, children }) => {
  const value = useMemo(() => ({ locale }), [locale]);
  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
};

export const useLocale = () => {
  const value = useContext(LocaleContext);
  if (!value) throw new Error('Missing LocaleContext Provider');
  return value;
};
