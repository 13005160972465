import { getStorage } from './getStorage';
import { IStorage } from './IStorage';
import { StorageSchema } from './schema';

type PromisifyMethods<T> = {
  [P in keyof T]: T[P] extends (...args: infer Params) => infer R
    ? (...args: Params) => Promise<R>
    : T[P];
};

function promisifyMethods<T extends { [key: string]: any }>(obj: T): PromisifyMethods<T> {
  const handler = {
    get(target: T, propKey: string) {
      const originalField = target[propKey];
      if (typeof originalField === 'function') {
        return function (...args: any[]) {
          const result = originalField(...args);
          return Promise.resolve(result);
        };
      } else {
        return originalField;
      }
    },
  };
  return new Proxy(obj, handler);
}

type AsyncStorageAPI = PromisifyMethods<IStorage<StorageSchema, keyof StorageSchema>>;

let storage: AsyncStorageAPI;
// Cross-platform uses require an async API
export function getAsyncStorage(): AsyncStorageAPI {
  if (storage) {
    return storage;
  }

  const syncStorage = getStorage();
  storage = promisifyMethods(syncStorage);

  return storage;
}
