import * as yup from 'yup';

import { fieldValidators, StorageSchema } from './schema';

// import { nr } from '../../newRelic';

export function storageValidator<T extends StorageSchema, K extends keyof StorageSchema>(
  key: K,
  value: unknown,
): value is T[K] {
  const schema = fieldValidators[key];

  if (schema && schema instanceof yup.BaseSchema) {
    const isValid = schema.isValidSync(value);
    if (!isValid) {
      // @TODO add logging after resolving cyclical dependency with NR
      // nr.noticeError(`Storage validation failed for key: ${key}`, {
      //   key,
      //   value: JSON.stringify(value),
      // });
    }
    return isValid;
  }

  // nr.noticeError(`Missing validator for storage key: ${key}`);
  return false;
}
