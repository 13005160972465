// LEGACY_NAME: yi-button
import { PropsWithChildren } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { default as MuiCircularProgress } from '@mui/material/CircularProgress';
import { spacing, SpacingProps } from '@mui/system';

import { Link } from 'gatsby';
import { resetStyles } from '~/src/styles/reset-styles-mixins';
import {
  black12,
  breakpoints,
  buttonCircularProgressZIndex,
  charcoalGrey,
  dustyOrangeTwo,
  dustyOrangeTwoHover,
  fontWeight,
  Gray98,
  greyBackground,
  lightGrey,
  pinkishOrange,
  turquoise,
  turquoiseHover,
  veryLightGrey,
} from '~/styles';

import { LocalizedLink } from '../LocalizedLink';

const buttonSimple = css`
  font-size: 0.6875rem;
  letter-spacing: 2px;
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  background: transparent;
  color: ${charcoalGrey};
  &:hover {
    text-decoration: underline;
  }
`;

const buttonBase = css`
  text-transform: uppercase;
  margin: 0;
  font-weight: 500;
  height: 51px;
  display: inline-flex;
  vertical-align: middle;
  text-align: center;
  border-radius: 2px;
  font-size: 14px;
  min-width: 175px;
  padding: 2px 30px 0;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 2px solid transparent;
  transition: background-color 0.25s ease-in-out;
  line-height: 1.14;
  letter-spacing: 1.25px;

  &:hover {
    text-decoration: none;
  }
`;

export const buttonDefault = css`
  ${buttonBase};
  color: #fff;
  background: ${dustyOrangeTwo};
`;

const buttonResponsive = css`
  ${buttonDefault};
  height: 36px;
  min-width: auto;
  padding: 2px 15px 0;
  ${breakpoints.minDesktop} {
    padding: 2px 20px 0;
  }
`;
export const buttonDefaultHover = css`
  &:hover,
  &:focus {
    color: #fff;
    background-color: ${dustyOrangeTwoHover};
  }
`;
const buttonThinOrange = css`
  ${buttonBase};
  border-color: ${dustyOrangeTwo};
  background-color: transparent;
  color: ${dustyOrangeTwo};
`;
const buttonThinOrangeHover = css`
  &:hover,
  &:focus {
    color: #fff;
    background-color: ${dustyOrangeTwoHover};
  }
`;
const buttonLight = css`
  ${buttonBase};
  color: #fff;
  border-color: #fff;
`;
const buttonLightHover = css`
  &:hover,
  &:focus {
    color: #fff;
    background-color: ${greyBackground};
  }
`;
const buttonDark = css`
  ${buttonBase};
  color: ${charcoalGrey};
  border-color: ${charcoalGrey};
`;
const buttonDarkHover = css`
  &:hover,
  &:focus {
    background-color: ${greyBackground};
  }
`;

const buttonContained = css`
  text-transform: uppercase;
  padding: 10px 16px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  text-align: left;
  border-radius: 4px;
  background-color: ${Gray98};
  letter-spacing: 1.25px;
  font-weight: 500;
  text-align: center;
  color: white;
  background-color: ${dustyOrangeTwo};
  font-size: 11px;
  ${breakpoints.minMobileLandscape} {
    font-size: 14px;
  }
`;

const buttonContainedHover = css`
  &:hover,
  &:focus {
    text-decoration: none;
    color: white;
    background-color: ${dustyOrangeTwoHover};
  }
`;

const buttonOutlined = css`
  ${buttonContained}
  border: solid 1px ${black12};
  background-color: transparent;
  color: ${dustyOrangeTwo};
`;

const buttonOutlinedHover = css`
  &:hover,
  &:focus {
    background-color: ${veryLightGrey};
  }
`;

const buttonOutlinedOrange = css`
  ${buttonContained};
  border: 2px solid ${dustyOrangeTwo};
  background-color: transparent;
  color: ${dustyOrangeTwo};
`;

const buttonOutlinedOrangeHover = css`
  &:hover {
    background-color: ${dustyOrangeTwo};
    color: white;
  }
`;

const buttonOutlinedTurquoise = css`
  ${buttonContained};
  border: 2px solid ${turquoise};
  background-color: transparent;
  color: ${turquoise};
`;
const buttonOutlinedTurquoiseHover = css`
  &:hover {
    background-color: ${turquoiseHover};
    color: white;
  }
`;

const buttonRound = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: inherit;
  font-size: 24px;
  transition: all 0.2s;
  font-size: 24px;
  box-shadow: none;
  :hover {
    transform: scale(1.1);
    background-color: inherit;
  }
`;

const buttonContainedTurquoise = css`
  ${buttonContained};
  padding: 6px 30px;
  text-transform: uppercase;
  background-color: ${turquoise};
  color: #fff;
  border: solid 1px ${turquoise};
`;

const buttonRounded = css`
  border-radius: 20px;
  padding-right: 22px;
  padding-left: 22px;
`;

const buttonContainedTurquoiseHover = css`
  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;
    background-color: ${turquoiseHover};
  }
`;

const buttonTurquoise = css`
  ${buttonDefault}
  margin-left: 7.5px;
  margin-right: 7.5px;
  margin-bottom: 15px;
  background: white;
  color: ${turquoise};
  :hover {
    background-color: ${greyBackground};
  }
`;

const buttonTurquoiseHover = css`
  &:hover,
  &:focus {
    color: ${turquoise};
    text-decoration: none;
    background-color: ${veryLightGrey};
  }
`;

const buttonOrangeText = css`
  letter-spacing: 0.7px;
  color: ${dustyOrangeTwo};
  border: none;
  background: transparent;
  text-transform: capitalize;
  white-space: nowrap;
  &:hover,
  &:focus {
    color: ${dustyOrangeTwoHover};
  }
`;
export type ButtonKind =
  | 'light'
  | 'thinOrange'
  | 'dark'
  | 'simple'
  | 'responsive'
  | 'contained'
  | 'containedTurquoise'
  | 'containedTurquoiseRounded'
  | 'round'
  | 'outlined'
  | 'outlinedOrange'
  | 'outlinedTurquoise'
  | 'outlinedTurquoiseRounded'
  | 'turquoise'
  | 'orangeText'
  | 'primary'
  | 'outlinedWhite'
  | 'text'
  | 'unstyled';

interface ButtonBase {
  kind?: ButtonKind;
  disabled?: boolean;
  showSpinner?: boolean;
  spinnerColor?: string;
  fullWidth?: boolean;
  className?: string;
  containerCss?: SerializedStyles;
}

const CircularProgress = styled(MuiCircularProgress, {
  shouldForwardProp: prop => isPropValid(prop) && !['spinnerColor'].includes(prop),
})<{ spinnerColor?: string }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  color: ${({ spinnerColor }) => (spinnerColor ? spinnerColor : pinkishOrange)};
  opacity: 1;
  z-index: ${buttonCircularProgressZIndex};
`;

const commonStyles = ({ disabled }: Pick<ButtonBase, 'disabled'>) => css`
  cursor: pointer;
  ${disabled &&
  css`
    -webkit-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out;
    background-color: ${veryLightGrey};
    color: ${lightGrey};
    &:hover,
    &:focus {
      cursor: not-allowed;
    }
  `};
`;

const linkStyles = css({
  textDecoration: 'none',
});

const buttonStylesByKind = (props: ButtonBase) => {
  const { kind, disabled } = props;
  switch (kind) {
    case 'light':
      return css`
        ${buttonLight};
        ${!disabled && buttonLightHover};
      `;
    case 'dark':
      return css`
        ${commonStyles(props)}
        ${buttonDark};
        ${!disabled && buttonDarkHover};
      `;
    case 'simple':
      return css`
        ${buttonSimple};
      `;
    case 'responsive':
      return css`
        ${buttonResponsive};
        ${!disabled && buttonDefaultHover};
      `;
    case 'thinOrange':
      return css`
        ${buttonThinOrange};
        ${!disabled && buttonThinOrangeHover};
      `;
    case 'contained':
      return css`
        ${buttonContained};
        ${!disabled && buttonContainedHover};
      `;
    case 'containedTurquoise':
      return css`
        ${buttonContainedTurquoise};
        ${!disabled && buttonContainedTurquoiseHover};
      `;
    case 'containedTurquoiseRounded':
      return css`
        ${buttonContainedTurquoise};
        ${buttonRounded};
      `;
    case 'round':
      return css`
        ${buttonRound};
      `;

    case 'outlined':
      return css`
        ${buttonOutlined};
        ${!disabled && buttonOutlinedHover};
      `;
    case 'outlinedOrange':
      return css`
        ${buttonOutlinedOrange};
        ${!disabled && buttonOutlinedOrangeHover};
      `;
    case 'outlinedTurquoise':
      return css`
        ${buttonOutlinedTurquoise};
        ${!disabled && buttonOutlinedTurquoiseHover};
      `;
    case 'outlinedTurquoiseRounded':
      return css`
        ${buttonOutlinedTurquoise};
        ${buttonRounded};
      `;
    case 'outlinedWhite':
      return css(resetStyles.button, {
        display: 'flex',
        height: '56px',
        padding: '8px 32px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50px',
        background: 'transparent',
        border: '1px solid #FFF',
        color: 'var(--Pure-White, #FFF)',
        fontFamily: 'Montserrat',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: fontWeight.bold,
        lineHeight: 'normal',
        letterSpacing: '0.8px',
        '&:hover,&:active': {
          background: 'var(--Secondary, #2CB3C6)',
          border: 'none',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.08)',
          color: 'var(--Pure-White, #FFF)',
        },
        '&:disabled': {
          border: 'none',
          background:
            'linear-gradient(0deg, rgba(44, 179, 198, 0.20) 0%, rgba(44, 179, 198, 0.20) 100%), #FFF',
        },
      });
    case 'turquoise':
      return css`
        ${buttonTurquoise};
        ${!disabled && buttonTurquoiseHover};
      `;
    case 'orangeText':
      return css`
        ${buttonOrangeText}
      `;
    case 'primary':
      return css(resetStyles.button, linkStyles, {
        display: 'flex',
        height: '56px',
        padding: '8px 32px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '30px',
        background: 'var(--Primary, #EF743C)',
        color: 'var(--Pure-White, #FFF)',
        fontFamily: 'Montserrat',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: fontWeight.bold,
        lineHeight: 'normal',
        letterSpacing: '0.8px',
        '&:hover,&:active': {
          textDecoration: 'none',
          background: 'var(--Primary-Hover, #D16230 )',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.08)',
          color: 'var(--Pure-White, #FFF)',
        },
        '&:disabled,&[disabled]': {
          background: 'var(--Grey-Background, #EDEDED)',
          color: 'var(--dark-text-54, rgba(88, 88, 88, 0.40))',
        },
      });

    case 'text':
      return css(resetStyles.button, {
        color: 'var(--Dark-Text, #27292D)',
        fontFamily: 'Montserrat',
        fontSize: '15px',
        lineHeight: 'normal',
        letterSpacing: '0.7px',
        fontStyle: 'normal',
        fontWeight: fontWeight.medium,
        padding: 0,
        [breakpoints.minTablet]: {
          fontSize: '18px',
          letterSpacing: '0.9px',
        },
      });
    case 'unstyled':
      return resetStyles.button;

    default:
      return css`
        ${buttonDefault};
        ${commonStyles(props)}
        ${!disabled && buttonDefaultHover};
      `;
  }
};

export const buttonStyles = (props: ButtonBase & SpacingProps) => css`
  ${spacing(props)};
  ${commonStyles(props)};
  ${buttonStylesByKind(props)};
`;

const Button_ = styled.button<ButtonBase & SpacingProps>`
  ${buttonStyles};
`;

type ButtonElProps = JSX.IntrinsicElements['button'];
type AnchorElProps = JSX.IntrinsicElements['a'];

type GetRenderComponentProps<T = 'button'> = T extends
  | React.ComponentType
  | typeof Link
  | typeof LocalizedLink
  ? React.ComponentProps<T>
  : T extends 'a'
  ? AnchorElProps
  : T extends 'button'
  ? ButtonElProps
  : {};

type RenderComponent = React.ComponentType | typeof Link | typeof LocalizedLink | 'a' | 'button';

export type ButtonCommonProps = SpacingProps &
  ButtonBase & {
    className?: string;
    containerCss?: SerializedStyles;
  };

type Props<E extends RenderComponent> = PropsWithChildren<
  {
    as?: E;
  } & GetRenderComponentProps<E> &
    ButtonCommonProps
>;

/**
 * we use '!important' here just because legacy 'fullWidth' param still in use, after refactoring all button usages to use 'styles' instead of 'fullWidth' prop, the '!important' can be safely removed
 */
export const styles = {
  fullWidth: css({
    width: '100% !important',
    'button,a': {
      width: '100% !important',
    },
  }),
  autoWidth: css({
    width: 'auto !important',
    'button,a': {
      width: 'fit-content !important',
    },
  }),
};

export const Button = <T extends RenderComponent>({
  children,
  showSpinner,
  spinnerColor,
  fullWidth = false,
  containerCss: wrapperCss,
  ...props
}: Props<T>): ReturnType<React.FC<Props<T>>> => {
  return (
    <div
      style={{ position: 'relative', width: fullWidth ? '100%' : 'auto', lineHeight: 'normal' }}
      css={wrapperCss}
    >
      <Button_ style={{ width: fullWidth ? '100%' : 'auto' }} {...props}>
        {children}
      </Button_>
      {showSpinner && <CircularProgress spinnerColor={spinnerColor} size={24} />}
    </div>
  );
};
