import React from 'react';

import { getResponsiveImageAttributes } from '~/src/common/image';
import { useLocale } from '~/src/components/LocaleProvider';

import { Image } from './elements';
import { getImageSrc } from './getImageSrc';

export const PromoImage: React.FC = props => {
  const { locale } = useLocale();
  const imageAttributes = getResponsiveImageAttributes(getImageSrc(locale));
  return <Image {...imageAttributes} alt="Docs&Films poster" {...props} />;
};
