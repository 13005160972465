import React, { PropsWithChildren } from 'react';
import { i18n_something_went_wrong_our_engineers_notified } from '@yi/i18n/phrases/i18n_something_went_wrong_our_engineers_notified';
import { i18n_sorry_this_page_is_not_working } from '@yi/i18n/phrases/i18n_sorry_this_page_is_not_working';

import { dustyOrangeTwo } from '~/styles';

import { useLocale } from './LocaleProvider';

export const UncaughtErrorFallback: React.FC<PropsWithChildren> = () => {
  const { locale } = useLocale();
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        textAlign: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundImage:
          'url(https://s3.amazonaws.com/images-s3.yogainternational.com/assets/content/site/error-background.jpg)',
        backgroundSize: 'cover',
      }}
    >
      <div style={{ paddingTop: '10%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <h1 style={{ fontSize: '6vmin', color: 'white', display: 'inline-block' }}>
            {i18n_sorry_this_page_is_not_working(locale)}
          </h1>
          <br />
          <h3
            style={{
              fontSize: '4vmin',
              paddingLeft: '10%',
              paddingRight: '10%',
              color: 'white',
              display: 'inline-block',
            }}
          >
            {i18n_something_went_wrong_our_engineers_notified(locale)}
          </h3>
          <br />
          <div style={{ paddingTop: '5%', color: 'white', display: 'inline-block' }}>
            <a style={{ textDecoration: 'none' }} href="/">
              <button
                style={{
                  textTransform: 'uppercase',
                  padding: '10px 16px',
                  height: 'auto',
                  minWidth: '175px',
                  width: 'auto',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  border: 'none',
                  borderRadius: '4px',
                  letterSpacing: '1.25px',
                  fontWeight: 500,
                  textAlign: 'center',
                  fontSize: '14px',
                  color: 'white',
                  backgroundColor: `${dustyOrangeTwo}`,
                }}
              >
                Home
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
