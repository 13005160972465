import React from 'react';

import { useFeatureFlag } from '../hooks';

/***
 * To improve performance and avoid race conditions in parts of the application
 * which utilize certain feature flags, we call them early to warm the Apollo cache.
 */
export const FeatureFlagCacheWarming: React.FC = () => {
  useFeatureFlag('brightcove_test_player');
  return null;
};
