import React, { useEffect } from 'react';
import { Locale } from '@yi/core';

import { oneTrust } from '~/src/common/oneTrust/oneTrust';

import { useLocale } from './LocaleProvider';

const onLocaleChange = (locale: Locale) => {
  oneTrust.setLocale(locale);
};

export const OnLocaleChange: React.FC = () => {
  const { locale } = useLocale();
  useEffect(() => {
    onLocaleChange(locale);
  }, [locale]);
  return null;
};
