// Brand colors
export const brickOrange = '#c74b0b';
export const pinkishOrange = '#ff642e';
export const turquoise = '#2db3c6';
export const turquoiseHover = '#209aab';
export const dustyOrangeTwo = '#ef743c'; // 239 116 60 .94 .45 .24 Primary 400
export const dustyOrangeTwoHover = '#d16230';
export const lightPurple = '#4f2dc6';
export const brightOrange = '#efb23c';
export const cheshire = '#d11672';
export const cerise = '#ce2471';
export const ceriseHover = '#bf2269';
export const yiBlue = '#2cb3c6';

// Greys (ordered light to dark)
export const containedLightHover = 'rgba(255,255,255, 0.3)';
export const black08 = 'rgba(0,0,0,0.08)';
export const smokeWhite = '#f1f1f1';
export const approxAzure = '#f8f9f9';
export const greyBackground = '#f7f7f7';
export const lightGreyVarient = '#dee2e6';
export const veryLightGrey = '#e9ebec';
export const cloudyBlue = '#c5d1d7';
export const lightGrey = '#bfbfbf';
export const lightGrey2 = '#cccccc';
export const greyish = '#a4a4a4';
export const greyishBlue = '#e8f0fe';
export const steelGrey = '#7e848c';
export const charcoalGrey = '#373f4a';
export const darkGrey = '#26292d';
export const vulcanGrey = '#373A40';
export const tunaGrey = '#4b4c4e';
export const ebony = '#2e3135';
export const materialGrey = '#495057';
export const gainsBoro = '#d8d8d8';
export const Gray98 = '#fafafa';
export const greyishBlack = '#26292d';
export const black12 = 'rgba(0,0,0,0.12)';
export const black20 = 'rgba(0,0,0,0.2)';
export const black28 = 'rgba(0,0,0,0.28)';
export const black38 = 'rgba(0,0,0,0.38)';
export const black50 = 'rgba(0,0,0,0.5)';
export const black54 = 'rgba(0,0,0,0.54)';
export const black54_hex = '#757575';
export const black60 = 'rgba(0,0,0,0.6)';
export const black80 = 'rgba(0,0,0,0.8)';
export const black87 = 'rgba(0,0,0,0.87)';
export const white87 = 'rgba(255,255,255,0.87)';
export const black33 = 'rgba(0,0,0,0.33)';
export const black16 = 'rgba(0,0,0,0.16)';
export const white25 = 'rgba(255,255,255,0.25)';
export const white50 = 'rgba(255,255,255,0.5)';
export const white54 = 'rgba(255,255,255,0.54)';
export const white60 = 'rgba(255,255,255,0.6)';
export const white75 = 'rgba(255,255,255,0.75)';
export const white12 = 'rgba(255,255,255,0.12)';
export const white9 = 'rgba(255,255,255,0.9)';
export const greyOverlay = 'rgba(38, 41, 45, 0.94)';

export const whisperGray = '#e6e6e6';
export const dustyOrange = '#ff642e'; // 255 100 46 1 .39 .18
export const copper = '#c65a28';

// Message or Indicator Colors
export const successGreen = '#155724';
export const lightGreen = '#43c743';
export const successLightGreen = '#05b183';
export const veryLightGreen = '#8af5ac';
export const errorRed = '#db4d62';
export const darkRed = '#f44336';
export const lightRed = '#ef3c61';
export const veryLightRed = '#fccccc';

// Onboard circle fill
export const blueFill = '#d5f0f4';
export const disabledGrey = '#e6e6e6';

// Teacher card overlay color
export const vividPurple = '#6202ee';

// Adyen iframe background color
export const slightGray = '#f7f8f9';

// Default system font
// tslint:disable-next-line ter-max-len
export const fontFamilySystem = `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`;
export const fontFamily = `'Montserrat', ${fontFamilySystem}`;

export const standardPaddingSm = '20px';
export const standardPaddingMd = '60px';
export const standardPaddingLg = '80px';
export const standardPaddingXl = '104px';

export const standardPaddingMobileMd = '36px';
export const standardPaddingMobileLg = '56px';
export const standardPaddingMobileXl = '76px';

export const standardBorder = `solid 1px ${cloudyBlue}`;

export const desktopLgWidthPixels = 1400;
export const desktopLgWidth = `${desktopLgWidthPixels}px`;
export const desktopWidthPixels = 1200;
export const desktopWidth = `${desktopWidthPixels}px`;
export const tabletLandscapeWidthPixels = 1024;
export const tabletLandscapeWidth = `${tabletLandscapeWidthPixels}px`;
export const tabletWidthPixels = 768;
export const tabletWidth = `${tabletWidthPixels}px`;
export const mobileLandscapeWidthPixels = 576;
export const mobileLandscapeWidth = `${mobileLandscapeWidthPixels}px`;
export const mobileWidthPixels = 320;
export const mobileWidth = `${mobileWidthPixels}px`;

export const gridBreakpoints = {
  xs: 0,
  sm: mobileLandscapeWidth,
  md: tabletWidth,
  lg: tabletLandscapeWidth,
  xl: desktopWidth,
};

export const containerMaxWidths = {
  sm: '540px',
  md: '720px',
  lg: '960px',
  xl: '1140px',
};

export const promotionBannerHeight = '61px';
export const promotionBannerTabletHeight = '80px';
export const promotionBannerMobileHeight = '120px';

export const courseCardWidth = '259px';
export const courseCardMobileWidth = '262px';

export const standardInputHeight = '50px';

export const slickArrowWidth = '50px';
export const slickArrowHeight = '50px';

export const sideNavWidth = '75px';

// Player
export const playerMobileBreak = '785px';

// Modals
// Padding applied to the modal body
export const modalInnerPadding = `1rem !default`;

export const modalDialogMargin = `.5rem !default`;
export const modalDialogMarginYSmUp = `1.75rem !default`;

export const modalTitleLineHeight = `$line-height-base !default`;

export const modalContentBg = `$white !default;`;
export const modalContentBorderColor = `rgba(#000, .2) !default;`;
export const modalContentBorderWidth = `1px !default;`;
export const modalContentBoxShadowXs = `0 .25rem .5rem rgba(#000, .5) !default;`;
export const modalContentBoxShadowSmUp = `0 .5rem 1rem rgba(#000, .5) !default;`;

export const modalBackdropBg = `#000 !default;`;
export const modalBackdropOpacity = `.5 !default;`;
export const modalHeaderBorderColor = `$gray-200 !default;`;
export const modalFooterBorderColor = `$modal-header-border-color !default;`;
export const modalHeaderBorderWidth = `$modal-content-border-width !default;`;
export const modalFooterBorderWidth = `$modal-header-border-width !default;`;
export const modalHeaderPadding = `1rem !default;`;

export const modalLg = `800px !default;`;
export const modalMd = `500px !default;`;
export const modalSm = `300px !default;`;

export const modalTransition = `transform .3s ease-out !default;`;

export const carouselBreakpointSm = 575;
export const carouselBreakpointMd = 767;
export const carouselBreakpointLg = 991;
export const carouselBreakpointXl = 1319;
export const carouselHeroBreakpointXl = 1400;

// Z-index's
// REFERENCE ONLY: this is set externally on Appcues service
export const appCuesZIndex = 1400;
// REFERENCE ONLY: Intercom uses this z-index for their chat
const intercomChatZIndex = 2147483001;

export const zeroZIndex = 0;
export const oneZIndex = 1;

export const uploadImageZIndex = 1200;
export const tabHeaderZIndex = 1400;
export const courseSalesPageTabsZIndex = 1299;
export const membershipDetailModalZIndex = 1700;
export const confirmUpdateMembershipModalZIndex = 1800;

export const testimonialCardZIndexes = { expanded: 2000, fab: 9999 };

export const fireworksZIndex = 999999;
export const heroZIndex = -1000;
export const advancedRefinementsModalZIndex = 10000;
export const headerZIndex = 9999;
export const leftPanelZIndex = headerZIndex - 1000;
export const zendeskHelperZIndex = 999999;

export const paywallModalCloseButtonZIndex = 9999;

export const favoriteCircularProgressZIndex = 999999;
export const buttonCircularProgressZIndex = 2;
export const followButtonCircularProgressZIndex = 99999;

export const coursePlayerZIndexes = {
  header: 3,
  container: 99,
  player: intercomChatZIndex + 1,
  sidebar: 1012,
};

export const onBoardingFooterZIndex = intercomChatZIndex + 1;
export const onBoardingZIndexes = [2, 3, 4, 5, 6, 7, 8, 9];

export const quickStartDialogZIndex = 9999;
export const jwPlayerModalZIndex = intercomChatZIndex + 1;
export const snackbarZIndex = jwPlayerModalZIndex + 1;

export const standardTeachersDropDownZIndex = 99999;
export const moreInfoPopoverZIndex = 9999999999;

export const carouselZIndex = 5;

export const progressBarZIndex = 999;

export const cardZIndex = 999;
export const baseCardZIndex = 99;
export const baseCardHoverZIndex = 999;
export const smallCardZIndex = 1;

export const courseInfoBannerZIndex = 10;
export const courseOutlineZIndex = 9;
export const courseBannerZIndex = 10;

export const currentUserZIndex = {
  avatar: 101,
  meditation: 100,
  class: 99,
};

export const searchBarZIndex = 2;
export const trackerCardZIndex = 2;
export const expandedPlaylistHeight = '95px';
export const playlistHeight = '45px';

export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};
