import React from 'react';

import { getStorage, nr } from '~/common';

import { useOnMount } from './hooks';

/*
 * Store UTM fields for 90 days, to be passed along with checkout for tracking attribution.
 *
 * UTM parameters are: utm_source, utm_medium, utm_campaign, utm_term, utm_content.
 */
const NINETY_DAYS = 1000 * 60 * 60 * 24 * 90;
const maybeRemoveOldUTM = () => {
  const storage = getStorage();
  const data = storage.getItem('utmData');
  if (!data) return;

  const captureDate = new Date(data.capturedOn);
  const timeSinceCapture = Date.now() - captureDate.getTime();

  // Remove data if date is invalid or expired
  if (Number.isNaN(timeSinceCapture) || timeSinceCapture < 0 || timeSinceCapture > NINETY_DAYS) {
    storage.removeItem('utmData');
  }
};

const captureUTM = () => {
  const storage = getStorage();
  const searchParams = new URLSearchParams(window.location.search);
  [...searchParams.keys()].forEach(k => {
    if (!k.startsWith('utm_')) searchParams.delete(k);
  });

  const utmQueryString = decodeURIComponent(searchParams.toString());
  if (utmQueryString) {
    storage.setItem('utmData', { capturedOn: new Date().toISOString(), utmQueryString });
  }
};

export const CaptureUTM: React.FC = () => {
  useOnMount(() => {
    try {
      maybeRemoveOldUTM();
      captureUTM();
    } catch (e) {
      nr.noticeError(`Error capturing UTM data: ${e}`);
    }
  });

  return null;
};
