import { useCallback, useState } from 'react';

import {
  rememberHasPromoSeen,
  shouldShowPromo,
  saveLastSeenSeriesPromo,
} from '~/src/common/lean-back';

export function usePromoState() {
  const [open, setOpen] = useState(true);

  const rememberChoice = useCallback(() => {
    setOpen(false);
    rememberHasPromoSeen();
  }, []);

  const postponePromo = useCallback(() => {
    setOpen(false);
    saveLastSeenSeriesPromo();
  }, []);

  return {
    open,
    postponePromo,
    rememberChoice,
    shouldShowPromo,
  };
}
