import React, { useEffect } from 'react';

import { identifyUser } from '~/src/common/analytics/identifyUser';
import { useCurrentUser } from '~/src/common/user';

export const SyncNewRelicMetadata: React.FC = () => {
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    identifyUser(currentUser);
  }, [currentUser]);

  return null;
};
