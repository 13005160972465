import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

import { CloseButton } from './elements';

interface Props {
  onClick: () => void;
}

export const CloseModalButton: React.FC<Props> = ({ onClick }) => {
  return (
    <CloseButton onClick={onClick}>
      <CloseIcon />
    </CloseButton>
  );
};
