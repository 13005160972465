import React, { PropsWithChildren } from 'react';
// @TODO i18n missing phrase
// import { i18n_article } from '@yi/i18n/phrases/i18n_article';
import Box from '@mui/material/Box';
import { i18n_class } from '@yi/i18n/phrases/i18n_class';
import { i18n_meditation } from '@yi/i18n/phrases/i18n_meditation';

import { useLocale } from '../LocaleProvider';

export const Heading: React.FC<PropsWithChildren<{ path: string }>> = ({ path }) => {
  const { locale } = useLocale();
  const text = /meditation\//.test(path)
    ? i18n_meditation(locale)
    : /article\//.test(path)
    ? 'article' /* @TODO i18n missing phrase i18n_article(locale) */
    : /class\//.test(path)
    ? i18n_class(locale)
    : '';

  return (
    <Box
      component="span"
      color="white"
      px="6px"
      fontSize="12px"
      textAlign="center"
      style={{ textTransform: 'capitalize' }}
    >
      {text}
    </Box>
  );
};
