import { Locale } from '@yi/core';

// import { ONETRUST_CATEGORIES } from './constants';
import { getOneTrustLibrary } from './getOneTrustLibrary';
import { getStorage } from '../storage';

const HIDE_BANNER_STYLES_SELECTOR = '#yi-hide-onetrust-style-elem';

const setBannerStylesDisabled = (disabled: boolean) => {
  try {
    const styleEl = document.querySelector<HTMLStyleElement>(HIDE_BANNER_STYLES_SELECTOR);
    if (styleEl) styleEl.disabled = disabled;
  } catch (e) {
    console.warn(`Failed to set OneTrust banner styles: ${e}`);
  }
};

const handleLoggedInUser = (ot: (typeof window)['OneTrust']) => {
  ot?.AllowAll();
};

export const oneTrust = {
  initialized: false,
  initialize: async () => {
    if (oneTrust.initialized) return console.warn(`OneTrust already initialized`);
    oneTrust.initialized = true;

    const ot = await getOneTrustLibrary();

    const token = getStorage().getItem('token');
    if (token && ot) handleLoggedInUser(ot);

    ot?.OnConsentChanged(async () => {
      // const activeGroups = await oneTrust.getActiveGroups();
      // console.log(
      //   '@TODO handle consent change',
      //   activeGroups.map(str => {
      //     return Object.entries(ONETRUST_CATEGORIES).find(([_, v]) => v === str)?.[0];
      //   }),
      // );
    });
  },
  showManageCookiesModal: () => getOneTrustLibrary().then(ot => ot?.ToggleInfoDisplay()),
  setLocale: (l: Locale) => getOneTrustLibrary().then(ot => ot?.changeLanguage(l)),
  getActiveGroups: async () => {
    // Assume that if main OneTrust library is active, we should also have `OnetrustActiveGroups`
    await getOneTrustLibrary();
    return window.OnetrustActiveGroups?.split(',').filter(v => v);
  },
  preventBannerFromShowing: () => setBannerStylesDisabled(true),
  allowBannerToShow: () => setBannerStylesDisabled(false),
  clearOneTrustCookies: () => {
    const cookieNames = ['OptanonConsent', 'OptanonAlertBoxClosed'];
    cookieNames.forEach(name => {
      document.cookie = name + '=; Max-Age=0';
    });
  },
  isGdprCountry: async () => {
    const ot = await getOneTrustLibrary();
    return ot?.GetDomainData().ConsentModel.Name === 'opt-in';
  },
  onLogout: async () => {
    const isGdpr = await oneTrust.isGdprCountry();
    if (isGdpr) {
      oneTrust.clearOneTrustCookies();
      oneTrust.allowBannerToShow();

      const ot = await getOneTrustLibrary();
      ot?.RejectAll();
    }
  },
  onLogin: async () => {
    const ot = await getOneTrustLibrary();
    if (ot) handleLoggedInUser(ot);
  },
};

oneTrust.initialize();
