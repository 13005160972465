const environments = ['development', 'experimental', 'staging', 'production', 'test'] as const;
type Environment = (typeof environments)[number];

export const getEnv = (onError: (e: Error) => void = () => undefined): Environment | null => {
  const env = process.env.GATSBY_NODE_ENV;
  if (env && environments.includes(env as Environment)) return env as Environment;

  const err = new Error(`Invalid environment: ${env}`);
  console.error(err);

  try {
    // Provide the error for reporting if we're not in local development
    if (!window.location.href.includes('localhost')) onError(err);
  } catch (e) {
    if (typeof window !== 'undefined') console.error(e);
  }

  return null;
};
