import { Values } from '@yi/core';

import { loggerFlagStorageKeys } from './loggerFlagStorageKeys';
import { getStorage } from '../storage/getStorage';

const storage = getStorage();

type StorageKey = Values<typeof loggerFlagStorageKeys>;
class Logger {
  private key: StorageKey;
  private logChangeMemory: { [k: string]: undefined | string } = {};

  constructor(key: StorageKey) {
    this.key = key;
  }

  private isEnabled = () => storage.getItem(this.key);

  log = (...args: any[]) => {
    if (!this.isEnabled()) return;
    const label = this.key.replace(/^yi\.\w+\./, ''); // remove storage prefix
    console.log(`${label}:`, ...args);
  };

  // Only log when `message` is a value different from the previous call.
  // This is useful for spammy logs inside functions called every React render cycle.
  logChange = (key: string, message: string) => {
    if (this.logChangeMemory[key] !== message) {
      this.logChangeMemory[key] = message;
      this.log(message);
    }
  };
}

export const loggers = {
  entitlements: new Logger(loggerFlagStorageKeys.ENTITLEMENTS),
  brooklyn: new Logger(loggerFlagStorageKeys.BROOKLYN),
  new_relic: new Logger(loggerFlagStorageKeys.NEW_RELIC),
};
