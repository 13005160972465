export interface ClassPageNavState {
  didNavigateFromDurationCarousel?: boolean;
}

export const isClassPageNavState = (s: unknown): s is ClassPageNavState =>
  typeof s === 'object' && s !== null && 'didNavigateFromDurationCarousel' in s;

export const didNavigateFromDurationCarousel = (s: unknown) =>
  isClassPageNavState(s) && !!s.didNavigateFromDurationCarousel;

export const isError = (e: unknown): e is Error => e instanceof Error;

export const isErrorOrString = (e: unknown): e is Error | string =>
  typeof e === 'string' || e instanceof Error;
