import React from 'react';
import { Global, css } from '@emotion/react';

import { variables } from '~/src/styles';

export const GlobalStyles: React.FC = () => {
  return (
    <Global
      styles={css`
        html,
        body {
          background-color: #f7f7f7;
          counter-reset: item;
          height: 100%; // required for Bootstrap modal to disable scroll of body while open
        }

        body {
          font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
            'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
          -webkit-font-smoothing: subpixel-antialiased;
          ${variables}
        }

        button,
        input,
        optgroup,
        select,
        textarea {
          font-family: inherit;
          font-size: inherit;
          line-height: inherit;
          margin: 0;
        }

        .LazyLoad {
          opacity: 0;
          transition: opacity 0.3s;
          &.is-visible {
            opacity: 1;
          }
        }
        label {
          display: inline-block;
          margin-bottom: 0.5rem;
        }
        p {
          margin-bottom: 1rem;
          margin-top: 0;
        }
        button:focus,
        :focus {
          outline: none;
        }

        ::selection {
          background: #ffded3;
          color: inherit;
        }

        a {
          text-decoration: none;
          color: inherit;
        }
        a:hover {
          color: inherit;
          text-decoration: underline;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-block-start: unset;
          margin-block-end: unset;
          color: inherit;
          font-family: inherit;
          font-weight: 500;
          line-height: 1.2;
          margin-bottom: 0.5rem;
        }
        h1 {
          font-size: 2.5rem;
        }

        h2 {
          font-size: 2rem;
        }

        h3 {
          font-size: 1.75rem;
        }

        h4 {
          font-size: 1.5rem;
        }
        h5 {
          font-size: 1.25rem;
        }
        h6 {
          font-size: 1rem;
        }

        // Hide recaptcha badge
        .grecaptcha-badge {
          visibility: hidden;
          margin-block-start: unset;
          margin-block-end: unset;
        }
      `}
    />
  );
};
