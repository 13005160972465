import { objectEntries } from '@yi/core';

import { nr } from '~/src/common/newRelic';
import { SSR, getEnv } from '~/src/common/utilities/utilities';

/***
 * Context:
 *
 * Currently Segment manages most of our analytics. Unfortunately the Facebook Pixel integration
 * in Segment only updates this data on page load. For legal reasons, we were forced to disable a
 * setting within the Facebook Pixel called `autoConfig`, which was previously automatically
 * collecting user data from form fields.
 *
 * This module aims to restore this lost user data, by using what may be an internal API, which
 * could break, to set the form data as `autoConfig` previously did.
 *
 * This is temporary code intended to get us through the holiday season, and we are expecting to
 * switch to a GTM based integration early next year.
 */

// UserData will be hashed automatically via a dedicated function in FB pixel
type UserData = { em?: string; fn?: string; ln?: string; zp?: string; external_id?: string };
type User = { email: string; first_name?: string | null; last_name?: string | null };
type PixelId = string;

type Pixel = { id: PixelId; userData: UserData; userDataFormFields: UserData };

type Fbq = {
  (e: 'init', p: PixelId, u?: UserData): void;
  getState(): undefined | { pixels?: Pixel[] };
};

declare global {
  interface Window {
    fbq?: Fbq;
  }
}

export function setFacebookPixelFormFieldsData(user: User) {
  try {
    const userData = mapUserToPixelArgs(user);

    if (SSR) throw new Error(`Attempted during SSR`);

    // If pixel not yet initialized, don't wait and retry. Other sources could have updated pixel
    // and we don't want to risk overwriting them with old data.
    const fbq = window.fbq;
    if (!fbq) throw new Error(`Missing fbq`);

    const state = fbq.getState();
    if (!state?.pixels) throw new Error(`getState() failed to return state.pixels`);

    state.pixels.forEach(pixel => {
      if (pixel.id === '1939922386485273') return; // don't interact with GTM test pixel

      objectEntries(userData).forEach(([k, v]) => {
        pixel.userDataFormFields[k] = v; // set data which will appear in the `udff` fields
      });
    });
  } catch (e) {
    const err = e instanceof Error ? e : new Error(`${e}`);
    err.message = `Failed to set form field user data in Facebook / Meta pixel: ${e}`;
    if (getEnv() !== 'production') console.warn(e);
    nr.noticeWarning('fb-pixel', { stacktrace: err.stack });
  }
}

function mapUserToPixelArgs(user: User) {
  const userData = {
    em: user.email,
    fn: user.first_name || '',
    ln: user.last_name || '',
  };

  // Remove falsey values
  return objectEntries(userData).reduce<UserData>((acc, [k, v]) => {
    if (v) acc[k] = v;
    return acc;
  }, {});
}
