import {
  mobileWidthPixels,
  mobileLandscapeWidthPixels,
  tabletWidthPixels,
  tabletLandscapeWidthPixels,
  desktopWidthPixels,
  desktopLgWidthPixels,
} from '~/styles';

export const breakpointsList = [
  mobileWidthPixels,
  mobileLandscapeWidthPixels,
  tabletWidthPixels,
  tabletLandscapeWidthPixels,
  desktopWidthPixels,
  desktopLgWidthPixels,
];
