import { getStorage } from '~/src/common/storage';
import { isNonEmptyString } from '~/src/common/utilities';

import { showInAWhileIntervalInMs } from './constants';
import { LAST_SEEN_SERIES_PROMO_STORAGE_KEY } from '../../storage-keys';

export function isNextShowReached() {
  const currentTimeInMs = Date.now();
  const storage = getStorage();
  const lastSeenInMsStorageValue = storage.getItem(LAST_SEEN_SERIES_PROMO_STORAGE_KEY);

  if (isNonEmptyString(lastSeenInMsStorageValue)) {
    const nextShowInMs = Number(lastSeenInMsStorageValue) + showInAWhileIntervalInMs;
    return currentTimeInMs > nextShowInMs;
  } else {
    return true;
  }
}
