import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';

import { Button as ButtonBase } from '~/src/components/buttons';
import { fontWeight, darkGrey, breakpoints, zendeskHelperZIndex } from '~/styles';

import { PromoImage as BasePromoImage } from '../common-components/PromoImage';

export const ImageContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 420px;
  max-height: 330px;
`;

export const PromoImage = styled(BasePromoImage)`
  @media (max-height: 750px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 100%;
    margin: auto;
  }
`;

export const H2 = styled.h2`
  font-size: 24px;
  font-weight: ${fontWeight.semiBold};
  line-height: 30px;
  letter-spacing: 0.03em;
  text-align: center;
  color: ${darkGrey};
`;

const topMargin = '20px';
const bottomMargin = '20px';
export const Modal = styled(Dialog)`
  z-index: ${zendeskHelperZIndex + 10};
  .MuiPaper-root {
    overflow: visible;
    padding: 32px 20px 44px;
    ${breakpoints.minMobileLandscape} {
      padding: 32px 60px 44px;
    }
    max-width: 680px;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: calc(var(--Header-Height) + ${topMargin});
    margin-bottom: ${bottomMargin};
    max-height: calc(100% - var(--Header-Height) - ${topMargin} - ${bottomMargin});
    @media (max-height: 750px) {
      height: 100%;
    }
  }
` as typeof Dialog;

export const ModalContentBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  height: 100%;
`;

export const Button = styled(ButtonBase)`
  font-weight: ${fontWeight.semiBold} !important;
  font-size: 14px !important;
  letter-spacing: 0.1em !important;
  padding: 15px 40px !important;
  ${breakpoints.minMobileLandscape} {
    padding: 15px 61px !important;
  }
`;

export const P = styled.p`
  font-size: 16px;
  font-weight: ${fontWeight.regular};
  letter-spacing: 0.03em;
  color: ${darkGrey};
  text-align: center;
`;
