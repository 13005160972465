import React from 'react';
import { ApolloClient, Observable } from '@apollo/client';
import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client/cache';
import { ApolloLink } from '@apollo/client/link/core';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { ApolloProvider as ApolloProvider_ } from '@apollo/client/react/context';
import { createUploadLink } from 'apollo-upload-client';
import { WEB_CLIENT_ATTR_HEADER } from '@yi/core/src/gaia';

import {
  notifyBugsnag,
  nr,
  getEnv,
  GRAPHQL_URL as graphqlUrl,
  tryGetFirebaseIdFromJwt,
} from '~/common';
import { getUserToken, isValidUserToken } from '~/src/common/user';

const graphqlAccessKey = process.env.GATSBY_GRAPHQL_ACCESS_KEY;

// Since we need to use createUploadLink in place of createHttpLink to support file uploads,
// we cannot use Apollo Boost client and must instead use ApolloClient.
// The following code is equivalent to Apollo Boost client, using code copied from this guide
// https://www.apollographql.com/docs/react/advanced/boost-migration/

// @TODO need to automate fragmentTypes to resolve union types on client side
// https://medium.com/airfrance-klm/easy-fragment-matching-with-graphql-code-generator-for-graphql-union-types-83a23daac581
// https://www.apollographql.com/docs/react/data/fragments/#generating-possibletypes-automatically

const request = async operation => {
  const token = getUserToken();

  const headers = { ...WEB_CLIENT_ATTR_HEADER };
  // Do not set X-Auth-Token header for login Mutation
  if (isValidUserToken(token) && operation.operationName !== 'DoLogin') {
    headers.Authorization = `Bearer ${token}`;
  }
  if (graphqlAccessKey) {
    headers['X-Access-Key'] = graphqlAccessKey;
  }
  operation.setContext({ headers });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      const startTime = Date.now();
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer))
        .finally(() => {
          const duration = Date.now() - startTime;
          const firebaseId = tryGetFirebaseIdFromJwt();
          const { operationName, variables } = operation;
          const shouldRedact = Object.keys(variables).find(k => k.includes('password')); // e.g. login, change email
          nr.addAction(nr.GRAPHQL_OPERATION_DURATIONS, {
            variables: shouldRedact ? '*REDACTED*' : variables,
            duration,
            operationName,
            firebaseId,
          });
        });

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

// NOTE: only retries Network errors, not GraphQL errors
const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: error => !!error,
  },
});

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const {
      query: {
        loc: {
          source: { body: query },
        },
      },
      variables,
    } = operation;
    graphQLErrors.forEach(({ message, locations, path }) => {
      const errorMsg = `[GraphQL error]:
        Message: ${message},
        Location: ${locations},
        Path: ${path},
        Query: ${query},
        Variables: ${JSON.stringify(variables)}`;
      // console.error(errorMsg);
      notifyBugsnag(errorMsg);
      nr.noticeError(errorMsg);
    });
  }
  if (networkError) {
    let metaData = {};
    try {
      metaData = {
        query: operation.query.loc.source.body,
        variables: JSON.stringify(operation.variables),
      };
    } catch (e) {
      metaData = { metaDataError: `${e}` };
    }

    // console.error(`[Network error]: ${networkError}`);
    const errorDetails = [`[Network error]: ${networkError}`, metaData];
    notifyBugsnag(...errorDetails);
    nr.noticeError(...errorDetails);
  }
});

const cache = new InMemoryCache({
  freezeResults: true,
  possibleTypes: {
    RegisterLive: ['RegisterLiveResponse', 'RegisterLiveError'],
  },
  typePolicies: {
    HomepageHeroContent: {
      // homepage hero item is identified by `hero_content->content_id`
      keyFields: ['hero_content', ['content_id']],
    },
    SiteVars: {
      merge: true,
    },
    DayActivity: {
      keyFields: ['date', ['timestamp']],
    },
    SeriesActivity: {
      /**
       * to be able to support "new episode" feature, we introduced "SYNTHETIC_NEXT_EPISODE_UUID".
       * This could break apollo cache, I.e. we can have different Series with the same SeriesActivity uuid (which is equal to SYNTHETIC_NEXT_EPISODE_UUID for synthetic next episode)
       * To solve this we have two options:
       * 1. Specify new logic for UUID on server-side (the drawback of this approach is that it adds inconsistency, because in all other places we use uuid)
       * 2. Customize ApolloProvider Cache. It keeps server-side consistent, but it requires customization of apollo cache on all platforms (web and RN)
       *
       * Currently the (2) options has chosen, because client-side cache is the reponsibility of client-side
       */
      keyFields: (args, ctx) => {
        if (args.id !== '') {
          return args.id;
        } else {
          const seriesEpisodeId = ctx.readField('id', args.content);

          return `SYNTHETIC_SERIES_EPISODE:${seriesEpisodeId}`;
        }
      },
    },
    CurrentUserSubscription: {
      keyFields: ['account_id'],
    },
  },
  dataIdFromObject: obj => {
    switch (obj.__typename) {
      case 'Category':
        // Prevent Categories of different languages overwriting each other
        // @TODO consider a Category fragment that includes `language`, and use in place of `name`
        return `${defaultDataIdFromObject(obj)}:${obj.name}`;
      default:
        return defaultDataIdFromObject(obj);
    }
  },
});

export const apolloClient = new ApolloClient({
  connectToDevTools: getEnv() === 'development',
  ssrMode: typeof window === 'undefined',
  cache,
  assumeImmutableResults: true,
  link: ApolloLink.from([
    retryLink,
    errorLink,
    requestLink,
    createUploadLink({
      uri: graphqlUrl,
    }),
  ]),
});

export const ApolloProvider = ({ children }) => (
  <ApolloProvider_ client={apolloClient}>{children}</ApolloProvider_>
);
