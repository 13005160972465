import styled from '@emotion/styled';

import { yiBlue } from '~/src/styles';

export const CloseButton = styled.button`
  position: relative;
  box-shadow: 0px 4px 4px 0px #00000040;
  height: 40px;
  width: 40px;
  background: ${yiBlue};
  border: none;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
  transform: translate(40%, -40%);
  cursor: pointer;
  &:disabled {
    background-color: #e9ebec;
    color: #bfbfbf;
    cursor: not-allowed;
  }
  svg {
    width: 24px;
    height: 24px;
    color: white;
  }
`;
