import { OnLocaleChange } from '~/src/components/OnLocaleChange';
import { HandleCrossWindowLoginAndLogout } from '~/src/components/side-effects/HandleCrossWindowLoginAndLogout';

export const GatsbyBrowserPageElementSideEffects: React.FC = () => {
  return (
    <>
      <OnLocaleChange />
      <HandleCrossWindowLoginAndLogout />
    </>
  );
};
