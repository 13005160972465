import { notifyBugsnag } from '../bugsnagClient';
import { isSSR } from '../utilities';

//https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#testing_for_availability
export function isLocalStorageAvailable(): boolean {
  if (isSSR()) {
    return false;
  }
  let storage;
  try {
    storage = window.localStorage;
    const x = 'yi.local_storage_feature_test';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    console.warn(`localeStorage not enabled, using in-memory fallback`);
    notifyBugsnag(`localeStorage not enabled, using in-memory fallback`);
    return false;
  }
}
