import { MS_IN_DAY } from '~/src/common/time-constants';

const days = 5;
const showPromoPeriodInDays = 30;
// TODO set real launch date
const testNow = 1689252154269;
export const startOfPromoInMs = testNow;

export const showInAWhileIntervalInMs = days * MS_IN_DAY;

export const showPromoPeriodInMs = showPromoPeriodInDays * MS_IN_DAY;
