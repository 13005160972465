import React, { useEffect } from 'react';

import { bugsnagClient } from '~/src/common/bugsnagClient';
import { useCurrentUser } from '~/src/common/user';

export const SyncBugsnagMetadata: React.FC = () => {
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (currentUser !== null) {
      bugsnagClient.setUser(
        currentUser.id,
        `${currentUser.first_name} ${currentUser.last_name}`,
        currentUser.email,
      );
    }
  }, [currentUser]);

  return null;
};
