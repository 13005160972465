import { css } from '@emotion/react';

import { breakpoints } from './styled-mixins';
import { fontWeight } from './styled-variables';

export const colors = {
  Primary: '#EF743C',
  PrimaryBackground: '#FFF3E280',
  PrimaryHover: '#F78E1B',
  DarkText: '#323439',
  GreyText: '#585858',
  DarkText54: '#58585866',
  wipActiveButtonColor: '#D16230',
  GreyBackground: '#EDEDED',
  PureWhite: '#FFF',
  White: '#FFF',
  NavigationBackground: '#26292D',
  Secondary: '#2CB3C6',
};

const colorsVariables = css({
  '--Primary': colors.Primary,
  '--Primary-Background': colors.PrimaryBackground,
  '--Primary-Hover': colors.PrimaryHover,
  '--Dark-Text': colors.DarkText,
  '--Grey-Text': colors.GreyText,
  '--White-Text': colors.White,
  '--dark-text-54': colors.DarkText54,
  '--Grey-Background': colors.GreyBackground,
  '--Pure-White': colors.PureWhite,
  '--White': colors.White,
  '--Navigation-Background': colors.NavigationBackground,
  '--Secondary': colors.Secondary,
});

export const paddingVariables = {
  p8: '8px',
  p10: '10px',
  p12: '12px',
  p16: '16px',
  p28: '28px',
  p24: '24px',
  p32: '32px',
  p40: '40px',
  p56: '56px',
  p64: '64px',
  p80: '80px',
  p120: '120px',
};

export const MaxDesktopContainerWidth = 1200;

const sizesVariables = css({
  '--Max-Desktop-Container-Width': `${MaxDesktopContainerWidth}px`,
});

const headerSize = css({
  '--Header-Height': '68px',
  [breakpoints.minDesktop]: {
    '--Header-Height': '80px',
  },
});

const sideNavSize = css({
  '--SideNav-Width': '75px',
});

export const variables = css([colorsVariables, sizesVariables, headerSize, sideNavSize]);

/**
 * NOTE
 * Typography styles contains media queries, so it doesn't work if you need to apply styles on specific resolutions
 * I.e.
 * text: css({
 *  [breakpoints.minTablet]: [ typography.heading1, {
 *  // styles here will not override styles from`typography.heading1` for `breakpoints.minTablet`
 *  // for example if you need to change fontSize it'll not work. only unique styles will work
 *  }]
 * }),
 *
 * ----------
 * Current approach is to just copy styles (we assume that this will not happen too often)
 * */

export const typography = {
  mobileHeading1: css({
    fontFamily: 'Montserrat',
    fontSize: '23px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '36px',
  }),
  mobileHeading2: css({
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '40px',
    letterSpacing: '1px',
  }),
  mobileHeading3: css({
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: fontWeight.semiBold,
    lineHeight: '40px',
    letterSpacing: '0.05em',
  }),
  mobileHeading3Bold: css({
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '40px',
    letterSpacing: '1px',
  }),

  mobileHeading4: css({
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: fontWeight.semiBold,
    lineHeight: '30px',
    letterSpacing: '0.05em',
  }),
  mobileButtonText: css({
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: fontWeight.bold,
    lineHeight: '19.5px',
    letterSpacing: '0.05em',
  }),
  mobileLabels: css({
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.45px',
  }),
  mobileLabelsBold: css({
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '22px',
    letterSpacing: '0.45px',
  }),
  heading1: css({
    fontFamily: 'Montserrat',
    fontSize: '23px',
    fontStyle: 'normal',
    fontWeight: fontWeight.bold,
    lineHeight: '36px',
    marginBottom: '0',
    [breakpoints.minTablet]: {
      fontSize: '36px',
      lineHeight: '50px',
      letterSpacing: '1.8px',
    },
    [breakpoints.minDesktop]: {
      fontSize: '60px',
      lineHeight: '80px',
      letterSpacing: '3px',
    },
  }),
  heading2: css({
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: fontWeight.semiBold,
    lineHeight: '40px',
    letterSpacing: '1px',
    marginBottom: '0',
    [breakpoints.minTablet]: {
      fontSize: '32px',
      lineHeight: '40px',
      letterSpacing: '1.6px',
    },
    [breakpoints.minDesktop]: {
      fontSize: '52px',
      lineHeight: '68px',
      letterSpacing: '2.6px',
    },
  }),
  heading3: css({
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: fontWeight.semiBold,
    lineHeight: '40px',
    letterSpacing: '1px',
    marginBottom: '0',
    [breakpoints.minDesktop]: {
      fontSize: '45px',
      lineHeight: '60px',
      letterSpacing: '2.25px',
    },
  }),
  heading4: css({
    [breakpoints.minDesktop]: {
      fontFamily: 'Montserrat',
      fontSize: '32px',
      fontWeight: fontWeight.semiBold,
      lineHeight: '40px',
      letterSpacing: '0.05em',
      marginBottom: '0',
    },
  }),
  label: css({
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: fontWeight.medium,
    lineHeight: '20px;',
    letterSpacing: '0.45px',
    [breakpoints.minDesktop]: {
      fontSize: '16px',
      lineHeight: '22px',
      letterSpacing: '0.8px',
    },
  }),
  bodyText: css({
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: fontWeight.medium,
    lineHeight: '22px',
    letterSpacing: '0.8px',
    [breakpoints.minTablet]: {
      fontSize: '22px',
      lineHeight: '40px',
      letterSpacing: '1.1px',
    },
    [breakpoints.minDesktop]: {
      fontSize: '20px',
      lineHeight: '40px',
      letterSpacing: '1px',
    },
  }),
  mobileBodyText: css({
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: fontWeight.medium,
    lineHeight: '22px',
    letterSpacing: '0.8px',
  }),
  mobileBodyTextBold: css({
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: fontWeight.semiBold,
    lineHeight: '26px',
    letterSpacing: '0.8px',
  }),
  tabletHeading1: css({
    fontFamily: 'Montserrat',
    fontSize: '36px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '50px',
    letterSpacing: '1.8px',
  }),
  desktopLargeBodyText: css({
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '40px',
    letterSpacing: '0.05em',
  }),
  desktopLargeBodyTextBold: css({
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '26px',
    letterSpacing: '1px',
  }),
  desktopSmallBodyText: css({
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '32px',
    letterSpacing: '0.9px',
  }),
  desktopSmallBodyTextSemibold: css({
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '32px',
    letterSpacing: '0.9px',
  }),
  subHeading1: css({
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: fontWeight.medium,
    [breakpoints.minTablet]: {
      fontSize: '24px',
      fontWeight: fontWeight.medium,
      lineHeight: '40px',
      letterSpacing: '0.05em',
    },
    [breakpoints.minDesktop]: {
      fontSize: '28px',
      lineHeight: '40px',
      letterSpacing: '1.4px',
    },
  }),
  subHeading2: css({
    fontFamily: 'Montserrat',
    [breakpoints.minDesktop]: {
      fontSize: '24px',
      fontWeight: fontWeight.medium,
      lineHeight: '40px',
      letterSpacing: '0.05em',
    },
  }),
  desktopCardText: css({
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: fontWeight.medium,
    lineHeight: '22px',
    letterSpacing: '0.05em',
  }),
  tabletLabels: css({
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '22px',
    letterSpacing: '0.8px',
  }),
  tabletBodyText: css({
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '30px',
    letterSpacing: '0.9px',
  }),
  tabletBodyTextSemibold: css({
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '30px',
    letterSpacing: '0.9px',
  }),
  tabletSubheading1: css({
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '40px',
    letterSpacing: '1.2px',
  }),
  tabletSubheading1Bold: css({
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: fontWeight.bold,
    lineHeight: '40px',
    letterSpacing: '0.05em',
  }),
  tabletSubheading2Bold: css({
    fontFamily: 'Montserrat',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '40px',
    letterSpacing: '1.1px',
  }),
  desktopHeading3: css({
    fontFamily: 'Montserrat',
    fontSize: '45px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '60px',
    letterSpacing: '2.25px',
  }),
  tabletSubheading2Semibold: css({
    fontFamily: 'Montserrat',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '40px',
    letterSpacing: '1.1px',
  }),
  tabletHeading2: css({
    fontFamily: 'Montserrat',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '50px',
    letterSpacing: '1.6px',
  }),
  tabletSmallLabels: css({
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '22px',
    letterSpacing: '0.75px',
  }),
  tabletLabelsBold: css({
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '22px',
    letterSpacing: '0.48px',
  }),
  desktopHeading4: css({
    fontFamily: 'Montserrat',
    fontSize: '32px',
    fontWeight: fontWeight.semiBold,
    lineHeight: '40px',
    letterSpacing: '0.05em',
  }),
  desktopHeading4Medium: css({
    fontFamily: 'Montserrat',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '50px',
    letterSpacing: '1.6px',
  }),
  desktopSubheading1: css({
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: fontWeight.medium,
    lineHeight: '40px',
    letterSpacing: '0.05em',
  }),
  desktopSubheading2Bold: css({
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '40px',
    letterSpacing: '1.2px',
  }),
  desktopSubheading2: css({
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '40px',
    letterSpacing: '1.2px',
  }),
  desktopLabels: css({
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '22px',
    letterSpacing: '0.8px',
  }),
  desktopLabelsBold: css({
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '22px',
    letterSpacing: '0.05em',
  }),
};
