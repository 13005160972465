import isNil from 'lodash/isNil';

import { IStorage } from './IStorage';
import { notifyBugsnag } from '../bugsnagClient';

type Validator<T, K extends keyof T> = (key: K, value: unknown) => value is T[K];

function unsafeValidator<T, K extends Extract<keyof T, string>>(
  key: K,
  value: unknown,
): value is T[K] {
  console.warn(
    `SafeLocalStorage. You're using unsafeValidator for ${key}-${value}. Prefer to provide real validator.`,
  );
  return true;
}

export class SafeLocalStorage<
  T extends { [key: string]: unknown },
  K extends Extract<keyof T, string>,
> {
  serialize: (value: unknown) => string;
  deserialize: (value: string) => unknown;
  validator: Validator<T, K>;
  constructor({
    serialize,
    deserialize,
    validator,
  }: {
    serialize: (value: unknown) => string;
    deserialize: (value: string) => unknown;
    validator: Validator<T, K>;
  }) {
    this.serialize = serialize;
    this.deserialize = deserialize;
    this.validator = validator;
  }
  setItem = (key: K, value: T[K]) => {
    let serializedValue;

    try {
      serializedValue = this.serialize(value);
    } catch (e) {
      notifyBugsnag(
        new Error(
          `SafeLocalStorage. value isn't serializable with the provided 'serialize'-function`,
        ),
      );
      return;
    }

    try {
      window.localStorage.setItem(key, serializedValue);
    } catch (e) {
      notifyBugsnag(e, {
        msg: `SafeLocalStorage. setItem for pair '${key}'-${value} cause error`,
      });
    }
  };

  getItem = (key: K) => {
    let rawValue;
    try {
      rawValue = window.localStorage.getItem(key);
    } catch (e) {
      notifyBugsnag(e, { msg: `LocalStorage. getItem-method error for '${key}'-key` });
      return null;
    }

    try {
      if (isNil(rawValue)) {
        return null;
      }
      const deserializedValue = this.deserialize(rawValue);
      if (this.validator(key, deserializedValue)) {
        return deserializedValue;
      }
      throw new Error('LocalStorage. Invalid type of value after deserialization');
    } catch (e) {
      if (!isNil(rawValue) && this.validator(key, rawValue)) {
        return rawValue;
      }
      notifyBugsnag(e, { msg: 'LocalStorage. Fatal error during the deserialization' });
      return null;
    }
  };

  removeItem = (key: K) => {
    try {
      return window.localStorage.removeItem(key);
    } catch (e) {
      notifyBugsnag(e, { msg: `SafeLocalStorage. removeItem with '${key}'-key cause error` });
    }
  };
}

export function createSafeLocalStorage<
  T extends { [key: string]: unknown },
  K extends Extract<keyof T, string>,
>({
  serialize = JSON.stringify,
  deserialize = JSON.parse,
  validator = unsafeValidator,
}: {
  serialize?: (value: unknown) => string;
  deserialize?: (value: string) => unknown;
  validator?: Validator<T, K>;
} = {}): IStorage<T, K> {
  return new SafeLocalStorage({
    serialize,
    deserialize,
    validator,
  }) as IStorage<T, K>; // TSFixMe
}
