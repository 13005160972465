import { Locale } from '@yi/core';

import { getLocalizedPath } from './navigation';
import { LOGGED_OUT_HOME_PATHNAME } from './routes';
import { SSR } from './utilities';

export const browserNavigate = (url: string) => {
  if (SSR) return;
  window.location.href = url;
};

export const browserNavigateToHomepage = (locale: Locale) => {
  if (SSR) return;

  const path = getLocalizedPath(LOGGED_OUT_HOME_PATHNAME, locale);
  browserNavigate(path);
};
