import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client/react/hooks';
import { gql } from '@yi/core';

import { useStorage } from '~/src/common/StorageProvider';

import { hasValidUserToken } from './hasValidUserToken';
import { isValidUserToken } from './isValidUserToken';

export const useCurrentUser = (opts?: { forceRefresh?: boolean }) => {
  const [getCurrentUser, { data, error, loading: rawLoading, refetch }] = useLazyQuery<
    gql.typesClient.Query_CurrentUser,
    {}
  >(gql.Query_CurrentUser, {
    ssr: false,
    // the default policy is 'cache-first'
    fetchPolicy: opts?.forceRefresh ? 'cache-and-network' : undefined,
  });

  const storage = useStorage();

  const [loading, setLoading] = useState<boolean>(hasValidUserToken());
  useEffect(() => {
    setLoading(rawLoading);
  }, [rawLoading]);

  /*
     to improve performance and reduce number of renders,
     we made initial optimistic request if "token" looks valid
   */
  const [hasCalled, setHasCalled] = useState(false);
  if (!hasCalled && hasValidUserToken()) {
    setHasCalled(true);
    getCurrentUser();
  }

  useEffect(() => {
    if (isValidUserToken(storage.token)) {
      getCurrentUser();
    }
  }, [getCurrentUser, storage.token]);

  const isLoggedIn = !error && !!data && !!data.CurrentUser;
  const currentUser = data?.CurrentUser ? data.CurrentUser : null;

  return { isLoggedIn, currentUser, loading, refetch };
};
