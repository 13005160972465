import { getStorage } from '~/src/common/storage';
import { isNonEmptyString } from '~/src/common/utilities';

import { HAS_SEEN_SERIES_PROMO_STORAGE_KEY } from '../../storage-keys';

export function hasAlreadySeen() {
  const storage = getStorage();
  const hasSeenStorageValue = storage.getItem(HAS_SEEN_SERIES_PROMO_STORAGE_KEY);
  return isNonEmptyString(hasSeenStorageValue);
}
