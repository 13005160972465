import { NavigateOptions } from '@reach/router';
import * as countryList from 'country-list';
import querystring from 'querystring';
import { gql, Locale } from '@yi/core';
import { i18n_afternoon } from '@yi/i18n/phrases/i18n_afternoon';
import { i18n_evening } from '@yi/i18n/phrases/i18n_evening';
import { i18n_friday } from '@yi/i18n/phrases/i18n_friday';
import { i18n_monday } from '@yi/i18n/phrases/i18n_monday';
import { i18n_morning } from '@yi/i18n/phrases/i18n_morning';
import { i18n_saturday } from '@yi/i18n/phrases/i18n_saturday';
import { i18n_sunday } from '@yi/i18n/phrases/i18n_sunday';
import { i18n_thursday } from '@yi/i18n/phrases/i18n_thursday';
import { i18n_tuesday } from '@yi/i18n/phrases/i18n_tuesday';
import { i18n_wednesday } from '@yi/i18n/phrases/i18n_wednesday';

import { navigate as gatsbyNavigate } from 'gatsby';

import type { Course, Bundle } from '../analytics';

import { notifyBugsnag } from '../bugsnagClient';
import { getEnv as _getEnv } from '../getEnv';

const localeToLanguageMap = {
  en: 'ENGLISH',
  es: 'SPANISH',
};

export function getLocaleFromLocation({ pathname }: { pathname: string }) {
  const locales = Object.keys(localeToLanguageMap);
  const captureLocale = new RegExp(`^/(${locales.join('|')})(/|$)`);
  const match = pathname.match(captureLocale);
  // English assumed if no locale segment
  return match ? match[1] : 'en';
}

export function getLocaleFromLanguage(language: gql.typesClient.Language) {
  const found = Object.entries(localeToLanguageMap).find(([, value]) => value === language);
  return found ? found[0] : undefined;
}
export function getLanguageFromLocale(locale: Locale) {
  return localeToLanguageMap[locale];
}

// SSR-safe, localized wrapper around Gatsby's navigate
export function navigate<TState extends Record<any, any> = {}>(
  to: string,
  opts?: NavigateOptions<TState>,
  { doNotLocalize }: { doNotLocalize?: boolean } = { doNotLocalize: false },
): null {
  // `en` locale is not represented in URL
  const urlLocales = Object.keys(localeToLanguageMap).filter(s => s !== 'en');

  if (!isSSR()) {
    const re = new RegExp(`^\/(${urlLocales.join('|')})`);
    const match = window.location.pathname.match(re);
    const locale = match ? `/${match[1]}` : '';
    const url = doNotLocalize ? to : `${locale}${to}`;
    gatsbyNavigate(url, opts);
  }

  return null; // allow components to `return navigate();`
}

export function classNames(classes: object) {
  return Object.entries(classes)
    .filter(([, value]) => value)
    .map(([key]) => key)
    .join(' ');
}

export function formatTitleCase(str = '') {
  return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function formatReplaceHyphenWithSpace(str = '') {
  return str.replace(/-/g, ' ');
}

export function formatReplaceSpacewithHyphen(str = '') {
  return str.replace(/\s+/g, '-');
}

export function formatReplaceUnderscoreWithSpace(str = '') {
  return str.replace(/_/g, ' ');
}

export function formatRemoveTrailingComma(str = '') {
  return str.replace(/,\s*$/, '');
}

export function formatPrettyClassLevel(str = '') {
  let result = str;
  result = result.replace(/-/, ' ');
  result = result.replace(/-/, '/');
  result = formatTitleCase(result);
  return result;
}

export function formatPretty(str = '', specialCase?: string) {
  if (specialCase === 'level') return formatPrettyClassLevel(str);
  let result = str;
  result = formatReplaceHyphenWithSpace(result);
  result = formatReplaceUnderscoreWithSpace(result);
  result = formatTitleCase(result);
  result = formatRemoveTrailingComma(result);
  return result;
}

export function formatKebabCase(str = '') {
  let result = str;
  result = formatReplaceSpacewithHyphen(result);
  result = result.toLowerCase();
  result = result.trim();
  return result;
}

export function breakQueryIntoRegexs(query: string) {
  return query
    .split(' ')
    .map(substr => {
      try {
        return new RegExp(substr, 'i');
      } catch (e) {
        return substr; // String produced invalid regex
      }
    })
    .filter((re): re is RegExp => re instanceof RegExp);
}

export const customHTMLTagsParser = (htmlStr: string, tagsArray: Array<string>) => {
  let parsedArray = [
    {
      type: 'html',
      data: htmlStr,
    },
  ];

  tagsArray.forEach((tag: string) => {
    const newArray: typeof parsedArray = [];
    parsedArray.forEach(data => {
      if (data.type !== 'html') {
        newArray.push(data);
        return;
      }
      data.data.split(`<${tag} hideOnRN>`).forEach((splittedItem: string) => {
        const closingTag = splittedItem.split(`</${tag}>`);

        if (closingTag.length === 1 && closingTag[0]) {
          newArray.push({ type: 'html', data: closingTag[0] });
        } else
          newArray.push(
            { type: tag, data: closingTag[0] || '' },
            { type: 'html', data: closingTag[1] || '' },
          );
      });
    });
    parsedArray = newArray;
  });
  return parsedArray;
};

export function scrollTop(offset = 0) {
  if (!isSSR()) {
    window.scrollTo(0, 0);
    if (document?.body?.scroll) {
      document.body.scroll({
        top: 0 + offset,
        behavior: 'smooth',
      });
    }
  }
}

export function truncate(str: string, maxLength: number, appendStr = '') {
  if (typeof str !== 'string' || str.length <= maxLength) return str;
  const result = str.slice(0, maxLength);
  const possibleEntityIdx = result.lastIndexOf('&');
  if (possibleEntityIdx === -1) return result + appendStr;
  const isHtmlEntity = /^&\S*;/.test(str.slice(possibleEntityIdx));
  const isTruncatingEntity = isHtmlEntity && str.indexOf(';', possibleEntityIdx) >= maxLength;
  // If mid-HTML-entity truncation is occurring, truncate to exclude entity
  return isTruncatingEntity ? str.slice(0, possibleEntityIdx) + appendStr : result + appendStr;
}

export function loadScript({
  src,
  abortLoadIf,
  id,
}: {
  src: string;
  abortLoadIf?(): boolean;
  id?: string;
}) {
  if (abortLoadIf?.()) return new Promise(resolve => resolve(true));
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  if (id) script.id = id;
  document.body.appendChild(script);
  return new Promise(resolve => {
    script.onload = () => {
      resolve(false);
    };
  });
}

export function runLegacyJs(jsCode: string) {
  const script = document.createElement('script');
  // tslint:disable-next-line no-inner-html
  script.innerHTML = jsCode;
  document.body.appendChild(script);
  return new Promise<void>(resolve => {
    script.onload = () => {
      resolve();
    };
  });
}

export function normalizeAccentedCharacters(input: string | string[] | any) {
  // Convert accented characters in a string to standard alphabet characters
  const normalize = (str: string) => {
    try {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    } catch (e) {
      return str;
    }
  };
  if (Array.isArray(input)) return input.map(normalize);
  if (typeof input === 'string') return normalize(input);
  return input;
}

interface ItemsWithCategoriesArray {
  categories?: ({ name: string } | null)[] | null;
}
export function filterByCategoriesFactory<I extends ItemsWithCategoriesArray>(category: string) {
  return (items: I[]) =>
    items.filter(item => item.categories?.some(c => !!c && c.name === category));
}
export function filterByStyleFactory<I extends { style: { name: string }[] }>(style: string) {
  return (items: I[]) => items.filter(item => item.style.some(({ name }) => name === style));
}

type Categories = ({ id: string } | null)[] | null;
export function includesCategory(categories: Categories, categoryToCompare: string) {
  return categories?.some(c => !!c && c.id === categoryToCompare);
}

export function redirectWithQueryStr(path: string) {
  if (!isSSR()) {
    return navigate(`${path}${window.location.search}`, { replace: true });
  }

  return null;
}

export const SSR = typeof window === 'undefined';
export function isSSR() {
  return typeof window === 'undefined';
}

export function getEnv() {
  return _getEnv(notifyBugsnag);
}

export function formatDateUTC(date: string, locale: Locale) {
  return new Date(date).toLocaleDateString(locale, { timeZone: 'UTC' });
}

function getDaysFromNow(date: Date) {
  const miliSecondInOneDay = 24 * 60 * 60 * 1000;
  const givenDate = new Date(date);
  const dateToday = new Date();
  const diff = Math.round((dateToday.getTime() - givenDate.getTime()) / miliSecondInOneDay);
  return diff;
}
export function getDaysUntil(date: Date) {
  const diff = getDaysFromNow(date);
  return diff < 0 ? Math.abs(diff) : 0;
}
export function getDaysSince(date: Date) {
  const diff = getDaysFromNow(date);
  return diff < 0 ? 0 : diff;
}

type TimeOfDayName = 'morning' | 'afternoon' | 'evening' | 'bedtime';
export function getTimeOfDayName(): TimeOfDayName {
  const date = new Date();
  const hours = date.getHours();
  if (hours >= 2 && hours < 12) return 'morning'; // 2a - 11:59a
  if (hours < 17) return 'afternoon'; // 12p - 4:59p
  if (hours < 21) return 'evening'; // 5p - 9p
  return 'bedtime'; // 9p - 1:59a
}

type TimeOfDayId = '196' | '197' | '198' | '283';
export function getTimeOfDayId(): TimeOfDayId {
  const timeOfDayLabel = getTimeOfDayName();
  if (timeOfDayLabel === 'morning') return '196';
  else if (timeOfDayLabel === 'evening') return '197';
  else if (timeOfDayLabel === 'bedtime') return '198';
  return '283';
}

export function getLocalizedDayAndTimeOfDay(locale: Locale) {
  const date = new Date();
  const timesofDay: { [K in TimeOfDayName]: string } = {
    morning: i18n_morning(locale),
    afternoon: i18n_afternoon(locale),
    evening: i18n_evening(locale),
    bedtime: i18n_evening(locale), // use evening for bedtime
  };
  const timeOfDay = timesofDay[getTimeOfDayName()] || '';
  const day =
    [
      i18n_sunday(locale),
      i18n_monday(locale),
      i18n_tuesday(locale),
      i18n_wednesday(locale),
      i18n_thursday(locale),
      i18n_friday(locale),
      i18n_saturday(locale),
    ][date.getDay()] || '';
  return { day, timeOfDay };
}

export function getLocalizedDefaultMemPromo(locale: Locale) {
  if (locale === 'es') return 'default-es';
  return 'default-en';
}

export function getLocalizedSignupSuccessPath(locale: Locale) {
  if (locale === 'en') return '/register/confirmation/';
  return `/${locale}/register/confirmation/`;
}

export function getLocalizedDefaultSignupPath(locale: Locale, pathPrefix = '/start') {
  if (locale === 'es') return `${pathPrefix}/${getLocalizedDefaultMemPromo('es')}`;
  return '/membership';
}

export const getLocalizedDateFromISOString = (
  isoDateString: string,
  locale: Locale,
): { weekDay: string; monthAndDay: string; timeText: string; abbreviatedTimeZone: string } => {
  const getLocalizedDateSubString = (options?: Intl.DateTimeFormatOptions) => {
    let dateSubString = '';
    try {
      const browserLocale = locale === 'es' ? 'es' : navigator.language;
      dateSubString = new Date(isoDateString).toLocaleString(browserLocale, options);
    } catch (e) {
      dateSubString = '';
    }
    return dateSubString;
  };

  const weekDay = getLocalizedDateSubString({
    weekday: 'long',
  });
  const monthAndDay = getLocalizedDateSubString({
    day: 'numeric',
    month: 'numeric',
  });

  const timeAndZoneText = getLocalizedDateSubString({
    timeZoneName: 'long',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
  const timeText = timeAndZoneText.split(' ').slice(0, 2).join('');
  const timeZone = timeAndZoneText.split(' ').slice(2, timeAndZoneText.length);
  const abbreviatedTimeZone = timeZone.map(value => value.slice(0, 1)).join('');

  return { weekDay, monthAndDay, timeText, abbreviatedTimeZone };
};

export function encodeQueryString(query: string) {
  return encodeURIComponent(query).replace(/\%20/g, '+');
}

export function scrollToElement(element: HTMLElement | null | undefined) {
  try {
    if (element) element.scrollIntoView({ behavior: 'smooth' });
  } catch (e) {
    console.warn(e);
    notifyBugsnag(e);
  }
}

// check if the given price is above Stripe's maximum amount,
// which is a number that has greater than 8 digits
// See https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts
export function isPriceAboveMaximum(price: string): boolean {
  // strip non-numeric characters
  const digits = price.replace(/[^0-9]/g, '');

  return digits.length > 8;
}

export function getCourseType(categories: Array<{ id: string }>) {
  if (categories.some(cat => cat.id === '274')) return 'PROGRAM';
  else if (categories.some(cat => cat.id === '295')) return 'PODCAST';
  return 'COURSE';
}

export function getCountries() {
  return countryList.getData().sort((a, b) => a.name.localeCompare(b.name));
}

export function getSingleCourseBundlePrice(course: Course, bundle: Bundle, applyDiscount: boolean) {
  const a_la_carte_price = course.a_la_carte_price || 0;
  if (!applyDiscount) {
    return a_la_carte_price;
  }
  const price = a_la_carte_price - (a_la_carte_price * bundle.discount_percent) / 100;
  return parseFloat(price.toFixed(2));
}

export function getCourseBundlePrice(bundle: Bundle, applyDiscount: boolean) {
  let total = 0;
  bundle.courses.forEach(course => {
    total += getSingleCourseBundlePrice(course, bundle, applyDiscount);
  });
  return parseFloat(total.toFixed(2));
}

export function getCoursePrice(course: Course) {
  return course.a_la_carte_price || 0;
}

export function getToken() {
  let token;
  try {
    token = window.localStorage.getItem('token');
  } catch {
    token = null;
  }
  return token;
}

export function getQueryString() {
  if (SSR) return '';
  return window.location.search;
}

/**
 * NOTE: This function return string only query string params.
 * If query string contains an array, then function return only first one element as the result for the field.
 * The name is pretty verbose and do a lot, but the purpose of keeping it and not splitting into parts is
 * that we used it already in multiple places and most of the time we need these 'cut' version of query params
 * */
export function extractStringParamsFromUrlSearch(location_?: { search: string }) {
  if (SSR && !location_) return {};
  const location = location_ || window.location;
  const searchParams = new URLSearchParams(location.search);

  return [...searchParams.keys()].reduce<{ [k: string]: undefined | string }>((acc, key) => {
    const value = searchParams.get(key);
    if (typeof value === 'string') {
      acc[key] = value;
    }
    return acc;
  }, {});
}

export function isMobile() {
  if (SSR) return false;
  const isAndroid = /Android/.test(navigator.userAgent);
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !('MSStream' in window) && !isAndroid;
  const isPhone = /phone|mobile/i.test(navigator.userAgent);
  return isPhone || isIOS || isAndroid;
}

export function addCurrencySymbol(amount: number | string, currency: string) {
  return amount.toLocaleString('en', { style: 'currency', currency });
}

type GetFbAndTwitterShareLinksProps = {
  full_url: string;
  title: string;
};

export const getFbAndTwitterShareLinks = ({ full_url, title }: GetFbAndTwitterShareLinksProps) => {
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?${querystring.stringify({
    u: full_url,
  })}`;
  const twitterLink = `https://twitter.com/intent/tweet?${querystring.stringify({
    text: title,
    via: 'YI_Mag',
    url: full_url,
    original_referer: full_url,
  })}`;
  return { facebookLink, twitterLink };
};

export const isAcceptedCurrency = (currencyCode: string) =>
  Object.values<string>(gql.typesClient.CurrencyCode).includes(currencyCode);

// @TODO generalize a utility to convert string to generic enum or undefined: github.com/microsoft/TypeScript/issues/30080
export const getAcceptedCurrency = (currencyCode: string) => {
  const enum_: { [k: string]: undefined | string } = gql.typesClient.CurrencyCode;
  const value = enum_[currencyCode] as unknown as gql.typesClient.CurrencyCode | undefined;
  return value || gql.typesClient.CurrencyCode.USD;
};

export const acceptedCurrencies = ['AUD', 'CAD', 'EUR', 'GBP', 'MXN', 'NZD', 'USD'];

export function msToTime(milisecs: number) {
  function pad(n: number, z = 2) {
    return `00${n}`.slice(-z);
  }

  const ms = milisecs % 1000;
  let s = (milisecs - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;

  return `${pad(hrs)}:${pad(mins)}:${pad(secs)}.${pad(ms, 3)}`;
}
