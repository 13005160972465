import { createInMemoryStorage } from './createInMemoryStorage';
import { createSafeLocalStorage } from './createSafeLocalStorage';
import { isLocalStorageAvailable } from './isStorageAvailable';
import { IStorage } from './IStorage';
import { StorageSchema } from './schema';
import { storageValidator } from './schema/storageValidator';
/**
 * Some browsers have the localStorage API disabled.
 *
 * For such browsers, we fall back onto an in-memory
 * localStorage stub (no persistence).
 *
 */
let storage: IStorage<StorageSchema, keyof StorageSchema>;

export function getStorage(): IStorage<StorageSchema, keyof StorageSchema> {
  if (storage) {
    return storage;
  }

  if (isLocalStorageAvailable()) {
    storage = createSafeLocalStorage({ validator: storageValidator });
  } else {
    storage = createInMemoryStorage();
  }
  return storage;
}
