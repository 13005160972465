import Bugsnag, { OnErrorCallback } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { getEnv } from './getEnv';
import { isErrorOrString } from './typeGuards';

const skipReporting = [
  new RegExp('Minified React error #418'), // Hydration failed because the initial UI does not match what was rendered on the server.
  new RegExp('Minified React error #423'), // There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.
  new RegExp('Minified React error #425'), // Text content does not match server-rendered HTML.
];

export const bugsnagClient = Bugsnag.start({
  apiKey: '434368ad8fcda1524c625d61df6b3238',
  appType: 'gatsby',
  releaseStage: getEnv() || 'INVALID_ENV',
  // Replace sensitive data sent to BugSnag with [redactedKeys]
  redactedKeys: ['token', /^password$/i],
  plugins: [new BugsnagPluginReact()],
  onError: error => {
    const errMsg = 'originalError' in error ? error.originalError : `${error}`;
    if (skipReporting.some(re => re.test(errMsg))) return false; // do not send to Bugsnag
    return true;
  },
});

export const notifyBugsnag = (
  error: unknown,
  metadata?: { [key: string]: any },
  onError?: OnErrorCallback,
) => {
  if (isErrorOrString(error)) {
    return Bugsnag.notify(
      error,
      onError ||
        (e => {
          if (metadata) {
            Object.entries(metadata).forEach(([k, v]) => e.addMetadata(k, v));
          }
          if (getEnv() === 'development') {
            console.error(e);
            // do not report errors from development environment
            return false;
          }
          return true;
        }),
    );
  }
};
