import React, { useCallback, useContext, useMemo, useRef, type PropsWithChildren } from 'react';

type Value = {
  calculateIsFetchLatestRequired: () => boolean;
  onFetchedLatest: () => void;
  onFetchingLatest: () => void;
};
const CurrentUserSubscriptionCacheUpdateContext = React.createContext<Value | undefined>(undefined);

const FETCH_LATEST_LIFETIME_IN_MS = 3 * 60 * 1000;

/**
 * 'fetchLatest' variable syncronize Gaia and YI subscription states. It's can be slow.
 * The goal of this provider to specify single policy for states syncronization.
 * In other words, it helps to request synchronization no more than 1 time per FETCH_LATEST_LIFETIME_IN_MS
 */
export const CurrentUserSubscriptonCacheUpdateProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const fetchLatestTimestampRef = useRef<number | null>(null);
  const isUpdatingRef = useRef<boolean>(false);

  const calculateIsFetchLatestRequired = useCallback(() => {
    const currentTimestamp = new Date().getTime();
    if (isUpdatingRef.current === true) {
      return false;
    }
    if (fetchLatestTimestampRef.current === null) {
      return true;
    }

    return currentTimestamp - fetchLatestTimestampRef.current > FETCH_LATEST_LIFETIME_IN_MS;
  }, []);

  const onFetchedLatest = useCallback(() => {
    isUpdatingRef.current = false;
    fetchLatestTimestampRef.current = new Date().getTime();
  }, []);

  const onFetchingLatest = useCallback(() => {
    isUpdatingRef.current = true;
  }, []);
  const value = useMemo(
    () => ({
      calculateIsFetchLatestRequired,
      onFetchedLatest,
      onFetchingLatest,
    }),
    [calculateIsFetchLatestRequired, onFetchedLatest, onFetchingLatest],
  );

  return (
    <CurrentUserSubscriptionCacheUpdateContext.Provider value={value}>
      {children}
    </CurrentUserSubscriptionCacheUpdateContext.Provider>
  );
};

export const useCurrentUserSubscriptionCacheUpdate = () => {
  const value = useContext(CurrentUserSubscriptionCacheUpdateContext);
  if (!value) throw new Error('Missing CurrentUserSubscriptionCacheUpdateContext Provider');
  return value;
};
