import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const minute = 1000 * 60;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 20 * minute,
      staleTime: 5 * minute,
      // If enabled, queries will refetch if stale every time window is refocused
      refetchOnWindowFocus: false,
    },
  },
});

export const ReactQueryProvider = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
