import React, { useEffect, useRef } from 'react';
import { Query_CurrentUser_CurrentUser } from '@yi/core/src/gql/generated/typesClient/Query_CurrentUser';

import { setFacebookPixelFormFieldsData } from '~/src/common/analytics/setFacebookPixelFormFieldsData';
import { useCurrentUser } from '~/src/common/user';

const makeKey = (u: Query_CurrentUser_CurrentUser) => `${u.id}|${u.first_name}|${u.last_name}`;

const INITIAL_LOAD = 'initial_load';

export const SyncMarketingAnalytics: React.FC = () => {
  const { currentUser, loading } = useCurrentUser();
  const prevKeyRef = useRef(INITIAL_LOAD);

  /***
   * In critical conversion paths, we expect the code will be updating the Facebook Pixel directly
   * to ensure timing (i.e. pixel has correct user data before sending conversion events).
   *
   * In other less critical paths, and as a catch all, we try to update the pixel with latest user
   * data whenever it meaningfully changes. This is expected to handle conversion cases where the
   * user logs in as part of checkout flow (e.g. existing account)
   */
  useEffect(() => {
    if (loading) return;

    const key = currentUser ? makeKey(currentUser) : 'anonymous';

    // Don't update on initial load. Segment is doing this, and we don't want to overwrite
    if (prevKeyRef.current === INITIAL_LOAD) {
      prevKeyRef.current = key;
      return;
    }

    // Update pixel if user data changes
    if (currentUser && prevKeyRef.current !== key) setFacebookPixelFormFieldsData(currentUser);
  }, [loading, currentUser]);

  return null;
};
