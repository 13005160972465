import { memoizeFunc } from './memoizeFunc';
import { parseJwt } from './parseJwt';
import { getUserToken } from './user/getUserToken';

const _tryGetFirebaseIdFromJwt = (): string => {
  try {
    const token = getUserToken();
    if (!token) return '';

    const parsedJwt = parseJwt(token);
    const parsedIdToken = parseJwt(parsedJwt.idToken);

    return parsedIdToken.user_id; // Firebase user ID
  } catch (e) {
    console.warn(`Failed to get ID from token ${e}`);
    return '';
  }
};

export const tryGetFirebaseIdFromJwt = memoizeFunc(_tryGetFirebaseIdFromJwt, 10000, v => !v);
