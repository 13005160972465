import Cookies from 'js-cookie';

export function getAnonymousId() {
  let anonymousId;

  const ajs_anonymous_id_cookie = Cookies.get('ajs_anonymous_id');

  if (ajs_anonymous_id_cookie) {
    // remove quotes from cookie
    anonymousId = ajs_anonymous_id_cookie.replace(/['"]+/g, '');
  }

  if (!anonymousId && window.analytics) {
    window.analytics.ready(() => {
      anonymousId = window.analytics.user().anonymousId();
    });
  }

  return anonymousId;
}
