import { IStorage } from './IStorage';

export class InMemoryStorage<
  T extends { [key: string]: unknown },
  K extends Extract<keyof T, string>,
> {
  private store: T = {} as T;

  getItem = <GetItemK extends keyof T>(key: GetItemK) => {
    const val = this.store[key];
    if (typeof val === 'undefined') {
      return null;
    }
    return val;
  };

  setItem = <SetItemK extends keyof T>(key: SetItemK, value: T[SetItemK]) => {
    this.store[key] = value;
  };

  removeItem = (key: K) => {
    delete this.store[key];
  };
}

export function createInMemoryStorage<
  T extends { [key: string]: unknown },
  K extends Extract<keyof T, string>,
>(): IStorage<T, K> {
  return new InMemoryStorage();
}
