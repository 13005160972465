type Params = {
  src: string;
  width?: number;
  quality?: number;
  format?: 'webp' | 'avif';
};

import { nr } from '~/src/common/newRelic';
import { isErrorOrString } from '~/src/common/typeGuards';

// some images in contentful set without protocol. It breaks URL constructor.
function getSafeSrc(src: string): string {
  const isRecoverableRegExp = new RegExp('^//.+');
  if (isRecoverableRegExp.test(src)) {
    return `https:${src}`;
  }
  return src;
}

export function getOptimizedImgSrc(params: Params) {
  const { src, width, quality = 70, format = 'webp' } = params;
  // if 'src' is not valid, then URL constructor throws error. In this case we provide fallback
  try {
    const safeSrc = getSafeSrc(src);
    const imageUrl = new URL(safeSrc);
    const searchParams = new URLSearchParams(imageUrl.search);
    searchParams.set('q', String(quality));

    if (typeof width !== 'undefined') {
      searchParams.set('w', String(Math.round(width)));
    }
    if (typeof format === 'string') {
      searchParams.set('fm', format);
    }

    return `${imageUrl.origin}${imageUrl.pathname}?${searchParams.toString()}`;
  } catch (error) {
    if (isErrorOrString(error)) {
      nr.noticeError(error, params);
    }
    return src;
  }
}
