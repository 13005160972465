import { createTheme } from '@mui/material/styles';
// Material ui custom break points
export const materialUiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1024,
      xl: 1200,
    },
  },
  typography: {
    fontFamily: '"Montserrat"',
  },
});
