import React from 'react';
import { i18n_a_whole_new_way } from '@yi/i18n/phrases/i18n_a_whole_new_way';
import { i18n_go_to_docs_and_films } from '@yi/i18n/phrases/i18n_go_to_docs_and_films';
import { i18n_introducing_our_new_docs_films } from '@yi/i18n/phrases/i18n_introducing_our_new_docs_films';

import { getLocalizedPath } from '~/src/common/navigation';
import { SERIES_PATHNAME } from '~/src/common/routes';
import { useLocale } from '~/src/components/LocaleProvider';
import { CloseModalButton } from '~/src/components/modals/CloseModalButton';

import { Modal, H2, ModalContentBody, Button, P, ImageContainer, PromoImage } from './elements';

type Props = {
  rememberChoice: () => void;
  postponePromo: () => void;
  open: boolean;
};

export const NewContentAlertModal: React.FC<Props> = ({
  rememberChoice: onRememberChoice,
  postponePromo: onPostponePromo,
  open,
}) => {
  const { locale } = useLocale();
  return (
    <Modal open={open} onClose={onPostponePromo}>
      <CloseModalButton onClick={onPostponePromo} />
      <ModalContentBody>
        <H2>{i18n_a_whole_new_way(locale)}</H2>
        <ImageContainer>
          <PromoImage />
        </ImageContainer>
        <P>{i18n_introducing_our_new_docs_films(locale)}</P>
        <Button
          as="a"
          href={getLocalizedPath(SERIES_PATHNAME, locale)}
          kind="contained"
          onClick={onRememberChoice}
        >
          {i18n_go_to_docs_and_films(locale)}
        </Button>
      </ModalContentBody>
    </Modal>
  );
};
