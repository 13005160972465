import { SerializedStyles, css } from '@emotion/react';

import {
  desktopLgWidthPixels,
  desktopWidthPixels,
  mobileLandscapeWidthPixels,
  mobileWidthPixels,
  tabletLandscapeWidthPixels,
  tabletWidthPixels,
} from '../styled-variables';

export const listReset = css`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const sizeMobileSm = (content: SerializedStyles) => css`
  @media (max-width: ${mobileWidthPixels - 1}px) {
    ${content};
  }
`;

export const sizeMobile = (content: SerializedStyles) => css`
  @media (max-width: ${mobileLandscapeWidthPixels - 1}px) {
    ${content};
  }
`;

export const sizeMobileUp = (content: SerializedStyles) => css`
  @media (min-width: ${mobileLandscapeWidthPixels - 1}px) {
    ${content};
  }
`;

export const sizeTablet = (content: SerializedStyles) => css`
  @media (max-width: ${tabletWidthPixels - 1}px) {
    ${content};
  }
`;

export const sizeTabletMin = (content: SerializedStyles) => css`
  @media (min-width: ${tabletWidthPixels}px) {
    ${content};
  }
`;

export const sizeTabletLg = (content: SerializedStyles) => css`
  @media (max-width: ${tabletLandscapeWidthPixels - 1}px) {
    ${content};
  }
`;

export const sizeTabletLgMin = (content: SerializedStyles) => css`
  @media (min-width: ${tabletLandscapeWidthPixels - 1}px) {
    ${content};
  }
`;
export const sizeDesktop = (content: SerializedStyles) => css`
  @media (max-width: ${desktopWidthPixels - 1}px) {
    ${content};
  }
`;
export const sizeDesktopLg = (content: SerializedStyles) => css`
  @media (min-width: ${desktopLgWidthPixels - 1}px) {
    ${content};
  }
`;
export const sizeDesktopLgDown = (content: SerializedStyles) => css`
  @media (max-width: ${desktopLgWidthPixels - 1}px) {
    ${content};
  }
`;

export const verticalAlign = (position = 'relative') => css`
  position: ${position};
  top: 50%;
  transform: translateY(-50%);
`;

export const grayscale = (value: number) => css`
  filter: #{'grayscale(' + ${value} + ')'};
`;

export const chrome = (content: SerializedStyles) => css`
  @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    ${content};
  }
`;
export const ie = (content: SerializedStyles) => css`
  @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    ${content};
  }
`;

export const printHidden = css({
  '@media print': {
    display: 'none',
  },
});

export const breakpoints = {
  minMobileLandscape: `@media (min-width: ${mobileLandscapeWidthPixels}px)`,
  minTablet: `@media (min-width: ${tabletWidthPixels}px)`,
  minTabletLandscape: `@media (min-width: ${tabletLandscapeWidthPixels}px)`,
  minDesktop: `@media (min-width: ${desktopWidthPixels}px)`,
  minDesktopLg: `@media (min-width: ${desktopLgWidthPixels}px)`,
};
