import React, { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';

import { greyOverlay, lightGrey, sizeMobile, turquoise, turquoiseHover, darkGrey } from '~/styles';

export const PlaylistWrapper = styled(Box)`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: ${darkGrey};
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
` as React.FC<PropsWithChildren<React.ComponentProps<typeof Box>>>;
export const AutoplayContainer = styled.div`
  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 400px;
  max-height: 130px;
  background-color: ${greyOverlay};
  border-radius: 4px;
  color: white;
  font-size: 14px;
  padding: 0px 2px;
  ${sizeMobile(css`
    width: 95%;
  `)}
`;

export const AutoplayToggle = styled(Switch)`
  .MuiSwitch-switchBase {
    color: ${lightGrey};
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: ${turquoise};
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${turquoiseHover};
  }
`;

export const AutoplayTitle = styled.div`
  font-weight: 500;
  font-size: 15px;
`;
