import { breakpointsList } from './breakpointsList';
import { getOptimizedImgSrc } from './getOptimizedImgSrc';

export type ResponsiveImageAttributes = {
  srcSet: string;
  sizes: string;
  src: string;
  loading?: 'lazy';
  decoding?: 'async';
};

export type Resolution = number;

type MaxViewportWidth = number;
type ImageWidth = number;

type Params = {
  sizes?: Array<[MaxViewportWidth, ImageWidth]>;
  defaultParams?: { width?: number };
};

export function getResponsiveImageAttributes(
  srcUrl: string,
  { sizes, defaultParams = {} }: Params = {},
): ResponsiveImageAttributes {
  const defaultScrOptimized = getOptimizedImgSrc({ src: srcUrl, ...defaultParams });

  const sizesAttribute = sizes
    ? `${sizes
        .map(([viewPortWidth, imageWidth]) => `(max-width: ${viewPortWidth}px) ${imageWidth}px`)
        .join(', ')}`
    : `${breakpointsList.map(r => `(max-width: ${r}px) ${r}px`).join(', ')}`;

  const srcSet = sizes
    ? `${sizes
        .map(([, imageWidth]) => [
          `${getOptimizedImgSrc({
            src: srcUrl,
            width: imageWidth,
          })} ${imageWidth}w`,
          `${getOptimizedImgSrc({
            src: srcUrl,
            width: imageWidth * 2,
            quality: 40,
          })} ${imageWidth * 2}w`,
        ])
        .join(', ')}`
    : `${breakpointsList
        .map(
          r =>
            `${getOptimizedImgSrc({
              src: srcUrl,
              width: Math.round(r),
            })} ${r}w`,
        )
        .join(', ')}`;
  return {
    sizes: sizesAttribute,
    srcSet,
    src: defaultScrOptimized,
    loading: 'lazy',
    decoding: 'async',
  };
}
