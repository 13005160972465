/* eslint-disable yi/avoid-relative-parent-imports-v2 */
import { runWithRetry } from '@yi/core/src/runWithRetry';

import { notifyBugsnag } from '../common/bugsnagClient';
import { loadScript, getEnv } from '../common/utilities/utilities';

export const loadZendeskScript = () => {
  setTimeout(() => {
    // @TODO use yi-core config and `getConfig('ZENDESK_KEY')` after yi-core import issue fixed
    const sandboxKey = '3bde84a9-0dc2-4625-8381-208424512068';
    const productionKey = '451fb1de-f8c1-4019-90bc-758cf44c54a8';
    const key = getEnv() === 'production' ? productionKey : sandboxKey;
    const src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;

    loadScript({ src, id: 'ze-snippet' }).then(() => {
      runWithRetry(
        () => {
          if (!window.zE || !window.zEACLoaded) throw new Error('Zendesk not loaded');
        },
        { maxRetries: 4 },
      ).catch(e => {
        notifyBugsnag(`Zendesk failed to load: ${e}`);
      });
    });
  }, 5000); // delay load for performance reasons
};
