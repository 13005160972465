import React, { useContext, PropsWithChildren, useMemo } from 'react';

interface Props<T> {
  pageContext: T;
}

/**
 * Why is any used here?
 * PageContextProvider infers types from passed params
 * usePageContext uses generic to determine type
 * So type in PageContext doesn't make any sense, it's not used anywhere
 */
const PageContext = React.createContext<any | undefined>(undefined);

export const PageContextProvider = <T,>({
  pageContext,
  children,
}: PropsWithChildren<Props<T>>): ReturnType<React.FC<PropsWithChildren<Props<T>>>> => {
  const value = useMemo(() => pageContext, [pageContext]);
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export const usePageContext = <T,>(): T => {
  const value = useContext<T>(PageContext);
  if (!value) throw new Error('Missing PageContext Provider');
  return value;
};
