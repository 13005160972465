import React, { useEffect } from 'react';

import { browserNavigateToHomepage } from '~/src/common/browserNavigate';

import { useLocale } from '../LocaleProvider';

/**
 *
 * The `storage` event only fires when localStorage values are changed within the
 * context of another document. For example if the user has multiple instances of
 * yogainternational.com open (e.g. multiple tabs/windows) and proceeds to log out
 * in one, this event will only fire in the inactive/blurred/background instances.
 *
 * This code exists to prevent many different types of errors produced by the
 * invalid state of an application instance whose in-memory values think the user
 * is logged in, but whose storage values think the user is logged out.
 *
 */

export const HandleCrossWindowLoginAndLogout: React.FC = () => {
  const { locale } = useLocale();
  useEffect(() => {
    // @TODO consider switching to Broadcast Channel API
    const listener = (event: StorageEvent) => {
      if (event.key === 'token') {
        const didLogin = !event.oldValue && !!event.newValue;
        const didLogout = !!event.oldValue && !event.newValue;

        if (didLogin || didLogout) browserNavigateToHomepage(locale);
      }
    };
    window.addEventListener('storage', listener);
    return () => window.removeEventListener('storage', listener);
  }, [locale]);

  return null;
};
