export const CLASSES_PATHNAME = '/classes';
export const MEDITATIONS_PATHNAME = '/meditations';
export const BREATHWORK_PATHNAME = '/pranayama';
export const SERIES_PATHNAME = '/series';
export const ARTICLES_PATHNAME = '/articles';
export const COURSES_PATHNAME = '/courses';
export const CHALLENGES_PATHNAME = '/challenges';
export const TEACHERS_PATHNAME = '/teachers';
export const LIBRARY_PATHNAME = '/library';
export const PREMIUM_TRAININGS_PATHNAME = '/premium-trainings';
export const PODCASTS_PATHNAME = '/podcasts';
export const POSES_PATHNAME = '/poses';
export const LOGGED_IN_HOME_PATHNAME = '/home';
export const LOGGED_OUT_HOME_PATHNAME = '/';
export const ACCOUNT_MEMBERSHIP_PATHNAME = '/account/membership';
export const MEMBERSHIP_PATHNAME = '/membership';
export const CHANGE_PLAN_PATHNAME = '/change-plan';
