const urlPathNameBlackList = [
  /^\/?(es\/)?register\/.*/i,
  /^\/?(es\/)?create-account\/.*/i,
  /^\/?(es\/)?billing\/.*/i,
  /^\/?(es\/)?membership\/.*/i,
  /^\/?(es\/)?winback-sign-in\/.*/i,
  /^\/?(es\/)?choose-plan\/.*/i,
];
export function isUrlPathnameInBlackList(pathname: string): boolean {
  const isInBlackList = urlPathNameBlackList.some(re => re.test(pathname));
  return isInBlackList;
}
