import { nr } from '../newRelic';
import { SSR } from '../utilities';

const INIT_ATTEMPT_INTERVAL = 500;
const MAX_ATTEMPTS = 30000 / INIT_ATTEMPT_INTERVAL;

export const getOneTrustLibrary = () =>
  new Promise<null | typeof window.OneTrust>((resolve, reject) => {
    if (SSR) return resolve(null);

    let attempts = 0;
    const tryResolveOneTrust = () => {
      attempts += 1;

      const oneTrust = window.OneTrust;
      // Ensure a method is present, previously seen firing early when object exists
      // but may not have been fully initialized internally, leading to errors.
      if (oneTrust?.OnConsentChanged) {
        return resolve(oneTrust);
      }
      if (attempts > MAX_ATTEMPTS) {
        return reject(new Error(`Failed to init after ${MAX_ATTEMPTS}`));
      }
      setTimeout(() => {
        tryResolveOneTrust();
      }, INIT_ATTEMPT_INTERVAL);
    };

    tryResolveOneTrust();
  }).catch(e => {
    nr.noticeError(`Error accessing OneTrust library on window: ${e}`);
  });
